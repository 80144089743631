import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  onValue,
  update,
  push,
  child,
  query as dbQuery,
  orderByChild,
  startAt,
  endAt,
  equalTo,
} from 'firebase/database';
import moment from 'moment';
// import axios from 'axios';
import {
  all,
  takeEvery,
  takeLatest,
  fork,
  put,
  call,
  select,
} from 'redux-saga/effects';
import actions from './actions';
import authActions from '../auth/actions';
import { notification } from '../../components';

const getIsProfessionalFromStore = (state) => state.Auth.professional;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getStartDateFromStore = (state) => state.PatientAlerts.startDate;

const getEndDateFromStore = (state) => state.PatientAlerts.endDate;

const getLastPatientIdFromStore = (state) => state.PatientAlerts.patientId;

const getSelectedAgendaFromStore = (state) => state.Agenda.selectedAgenda;

function fetchAlertsFromDB(
  // { search, append },
  // storeSearch,
  addressUid,
  unified = {},
  mainUser,
  startDate,
  endDate,
) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let startAtToUse = null;
  let endAtToUse = null;
  if (startDate && endDate) {
    startAtToUse = moment(startDate).format('YYYY-MM-DD');
    endAtToUse = moment(endDate).format('YYYY-MM-DD');
  } else {
    // tenho duvida, drico quando ler aqui me avisa
    startAtToUse = moment().tz('America/Sao_Paulo').add(-7, 'days').format('YYYY-MM-DD');
    endAtToUse = moment().tz('America/Sao_Paulo').format('YYYY-MM-DD');
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const databaseRef = ref(db, `/unified/${unified.id}/patientAlerts`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('alertDate'),
      startAt(startAtToUse),
      endAt(`${endAtToUse}\uf8ff`),
    );
    return new Promise((resolve) => {
      onValue(queryRef, resolve, { onlyOnce: true });
    });
  }
  const databaseRef = ref(db, `/users/${uid}/patientAlerts`);
  const queryRef = dbQuery(
    databaseRef,
    orderByChild('alertDate'),
    startAt(startAtToUse),
    endAt(`${endAtToUse}\uf8ff`),
  );
  return new Promise((resolve) => {
    onValue(queryRef, resolve, { onlyOnce: true });
  });
}

export function* getAlertsForTheDate() {
  yield takeEvery([
    authActions.LOGIN_SUCCESS,
    actions.FETCH_PATIENT_ALERT,
  ], function* (action) {
    try {
      const professional = yield select(getIsProfessionalFromStore);
      if (professional) {
        yield put({ type: actions.FETCH_PATIENT_ALERT_WAITING });
        const unified = yield select(getUnifiedTokenStore);
        const mainUser = yield select(getMainUserFromStore);
        const addressUid = yield select(getSelectedAddressFromStore);
        const {
          startDate,
          endDate,
        } = action.payload;
        const alerts = yield call(
          fetchAlertsFromDB,
          addressUid,
          unified,
          mainUser,
          startDate,
          endDate,
        );
        const alertArr = [];
        alerts.forEach((p) => {
          alertArr.push({
            ...p.val(),
            alertKey: p.key,
          });
        });
        if (action.type === authActions.LOGIN_SUCCESS) {
          yield put({
            type: actions.FETCH_PATIENT_ALERT_FOR_WEEK_SUCCESS,
            payload: alertArr,
          });
        } else {
          yield put({
            type: actions.FETCH_PATIENT_ALERT_SUCCESS,
            payload: {
              alerts: alertArr,
              startDate,
              endDate,
            },
          });
        }
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.FETCH_PATIENT_ALERT_ERROR });
    }
  });
}

function updateAlertInDB(addressUid, unified, mainUser, agendaId, updatedAlert, deleteAlert) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};

  if (!deleteAlert) {
    // Normal update path for patient alerts
    let databasePath;
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      databasePath = `/unified/${unified.id}/patientAlerts/${updatedAlert?.alertKey}`;
    } else {
      databasePath = `/users/${uid}/patientAlerts/${updatedAlert?.alertKey}`;
    }
    updates[databasePath] = updatedAlert;
  }

  // Handle deletion of appointment alert and potentially other related children
  if (deleteAlert && updatedAlert.appointmentId && updatedAlert.procedureId) {
    // Remove the alert from the appointment alerts
    const appointmentAlertPath = `requests/${uid}/${agendaId}/confirmed/${updatedAlert.appointmentId}/appointmentAlerts/${updatedAlert.procedureId}`;
    updates[appointmentAlertPath] = null;

    // Remove the actual patient alert
    let patientAlertPath;
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      patientAlertPath = `/unified/${unified.id}/patientAlerts/${updatedAlert?.alertKey}`;
    } else {
      patientAlertPath = `/users/${uid}/patientAlerts/${updatedAlert?.alertKey}`;
    }
    updates[patientAlertPath] = null;
  }

  // Perform batch update
  return update(ref(db), updates);
}

export function* updateAlert() {
  yield takeEvery(actions.UPDATE_PATIENT_ALERT, function* (action) {
    try {
      yield put({ type: actions.UPDATE_PATIENT_ALERT_WAITING });
      const unified = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const { alert, updates } = action.payload;
      const updatedAlert = { ...alert, ...updates };
      yield call(
        updateAlertInDB,
        addressUid,
        unified,
        mainUser,
        agendaId,
        updatedAlert,
        _.isNull(updates),
      );
      const startDate = yield select(getStartDateFromStore);
      const endDate = yield select(getEndDateFromStore);
      if (startDate && endDate) {
        yield put({
          type: actions.FETCH_PATIENT_ALERT,
          payload: {
            startDate,
            endDate,
          },
        });
      } else {
        const id = yield select(getLastPatientIdFromStore);
        if (id) {
          yield put({
            type: actions.FETCH_SINGLE_PATIENT_ALERT,
            payload: id,
          });
        }
      }
    } catch (error) {
      console.warn(error);
      // yield put({ type: actions.UPDATE_PATIENT_ALERT_ERROR });
    }
  });
}

function fetchAlertsForSinglePatientFromDB(
  addressUid,
  unified = {},
  mainUser,
  id,
) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const databaseRef = ref(db, `/unified/${unified.id}/patientAlerts`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('user'),
      equalTo(id),
    );
    return new Promise((resolve) => {
      onValue(queryRef, resolve, { onlyOnce: true });
    });
  }
  const databaseRef = ref(db, `/users/${uid}/patientAlerts`);
  const queryRef = dbQuery(
    databaseRef,
    orderByChild('user'),
    equalTo(id),
  );
  return new Promise((resolve) => {
    onValue(queryRef, resolve, { onlyOnce: true });
  });
}

export function* getAlertsForSinglePatient() {
  yield takeEvery(actions.FETCH_SINGLE_PATIENT_ALERT, function* (action) {
    try {
      yield put({ type: actions.FETCH_SINGLE_PATIENT_ALERT_WAITING });
      const unified = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const alerts = yield call(
        fetchAlertsForSinglePatientFromDB,
        addressUid,
        unified,
        mainUser,
        action.payload,
      );
      const alertArr = [];
      alerts.forEach((p) => {
        alertArr.push({
          ...p.val(),
          alertKey: p.key,
        });
      });
      yield put({
        type: actions.FETCH_SINGLE_PATIENT_ALERT_SUCCESS,
        payload: {
          id: action.payload,
          alertArr,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.FETCH_SINGLE_PATIENT_ALERT_ERROR });
    }
  });
}

function createAlertOnDB(payload, mainUser, agendaId) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(dbRef, `/users/${uid}/patientAlerts`)).key;
  updates[`/users/${uid}/patientAlerts/${pushKey}`] = payload;
  if (payload.appointmentId && payload.procedureId) {
    updates[`requests/${uid}/${agendaId}/confirmed/${payload.appointmentId}/appointmentAlerts/${payload.procedureId}/alertId`] = pushKey;
  }
  return update(dbRef, updates);
}

export function* createAlertOnDb() {
  yield takeLatest(actions.INSERT_PATIENT_ALERT, function* (action) {
    try {
      yield put({ type: actions.INSERT_PATIENT_ALERT_WAITING });
      const mainUser = yield select(getMainUserFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      yield call(createAlertOnDB, action.payload, mainUser, agendaId);
      const {
        alertDate,
      } = action.payload;
      const startDate = yield select(getStartDateFromStore);
      const endDate = yield select(getEndDateFromStore);
      if (startDate
        && endDate
        && (moment(alertDate, 'YYYY-MM-DD').isSameOrBefore(moment(endDate, 'YYYY-MM-DD'))
        && moment(alertDate, 'YYYY-MM-DD').isSameOrAfter(moment(startDate, 'YYYY-MM-DD')))) {
        yield put({
          type: actions.FETCH_PATIENT_ALERT,
          payload: {
            startDate,
            endDate,
          },
        });
      } else {
        const id = yield select(getLastPatientIdFromStore);
        if (id) {
          yield put({
            type: actions.FETCH_SINGLE_PATIENT_ALERT,
            payload: id,
          });
        }
      }
      notification('success', 'Criado com sucesso', `Alerta foi criado foi para o dia ${moment(alertDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}`);
      yield put({
        type: actions.INSERT_PATIENT_ALERT_SUCCESS,
      });
    } catch (error) {
      notification('error', 'O Alerta não foi criado, confira os dados informados ou tente novamente mais tarde.');
      console.warn(error);
      yield put({
        type: actions.INSERT_PATIENT_ALERT_ERROR,
      });
    }
  });
}

function deleteAlertFromDB(alertId, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/users/${uid}/patientAlerts/${alertId}`] = null;
  return update(dbRef, updates);
}

export function* deleteAlertOnDb() {
  yield takeLatest(actions.DELETE_PATIENT_ALERT, function* (action) {
    try {
      const mainUser = yield select(getMainUserFromStore);
      yield call(deleteAlertFromDB, action.payload, mainUser);
      yield put({
        type: actions.DELETE_PATIENT_ALERT_SUCCESS,
      });
      yield put({
        type: actions.FETCH_PATIENT_ALERT,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.DELETE_PATIENT_ALERT_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createAlertOnDb),
    fork(getAlertsForTheDate),
    fork(deleteAlertOnDb),
    fork(getAlertsForSinglePatient),
    fork(updateAlert),
  ]);
}
