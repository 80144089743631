function ascendingSort(contact1, contact2) {
  const name1 = contact1.name ? contact1.name.toUpperCase() : '~';
  const name2 = contact2.name ? contact2.name.toUpperCase() : '~';
  if (name1 > name2) {
    return 1;
  }
  if (name1 === name2) {
    return 0;
  }
  return -1;
}

const contactActions = {
  GET_PATIENTS_REQUEST: 'GET_PATIENTS_REQUEST',
  PATIENTS_FETCH_SUCCESS: 'PATIENTS_FETCH_SUCCESS',
  DELETED_PATIENTS_FETCH_SUCCESS: 'DELETED_PATIENTS_FETCH_SUCCESS',
  SEARCHING_PATIENTS: 'SEARCHING_PATIENTS',
  SEARCHING_PATIENTS_APPEND: 'SEARCHING_PATIENTS_APPEND',
  PATIENTS_FETCH_ERROR: 'PATIENTS_FETCH_ERROR',
  SAVE_PATIENT_REQUEST: 'SAVE_PATIENT_REQUEST',
  SAVE_PATIENT_ERROR: 'SAVE_PATIENT_ERROR',
  START_SAVE_PATIENT_UPLOAD: 'START_SAVE_PATIENT_UPLOAD',
  DISCARD_PATIENT_CHANGES: 'DISCARD_PATIENT_CHANGES',
  ADD_CONTACT_REQUEST: 'ADD_CONTACT_REQUEST',
  ADD_CONTACT: 'ADD_CONTACT',
  EDIT_CONTACT: 'EDIT_CONTACT',
  DELETE_CONTACT: 'DELETE_CONTACT',
  DELETE_CONTACT_SUCCESS: 'DELETE_CONTACT_SUCCESS',
  START_DELETE_CONTACT: 'START_DELETE_CONTACT',
  RECOVER_CONTACT: 'RECOVER_CONTACT',
  RECOVER_CONTACT_SUCCESS: 'RECOVER_CONTACT_SUCCESS',
  RECOVER_CONTACT_ERROR: 'RECOVER_CONTACT_ERROR',
  START_RECOVER_CONTACT: 'START_RECOVER_CONTACT',
  CHANGE_CONTACT: 'CHANGE_CONTACT',
  EDIT_VIEW: 'EDIT_VIEW',
  FETCH_PATIENT_DATA: 'FETCH_PATIENT_DATA',
  SEARCH_PATIENTS_REQUEST: 'SEARCH_PAZIPNTS_REQUEST',
  SEARCH_ZIP_CODE_REQUEST: 'SEARCH_CEP_CODE_REQUEST',
  SEARCH_ZIP_CODE_SUCCESS: 'SEARCH_CEP_CODE_SUCCESS',
  SEARCH_ZIP_CODE_ERROR: 'SEARCH_ZIP_CODE_ERROR',
  PATIENT_HISTORY_REQUEST: 'PATIENT_HISTORY_REQUEST',
  PATIENT_HISTORY_SUCCESS: 'PATIENT_HISTORY_SUCCESS',
  PATIENT_HISTORY_ERROR: 'PATIENT_HISTORY_ERROR',
  RESET_PATIENT_HISTORY: 'RESET_PATIENT_HISTORY',
  PATIENT_HISTORY_WAITING: 'PATIENT_HISTORY_WAITING',
  RESET_SEARCH_PATIENTS_SETTINGS: 'RESET_SEARCH_PATIENTS_SETTINGS',
  UPDATE_APPOINTMENTS_USER_INFO: 'UPDATE_APPOINTMENTS_USER_INFO',
  FIND_POSSIBLE_MERGE_PATIENTS_REQUEST: 'FIND_POSSIBLE_MERGE_PATIENTS_REQUEST',
  FIND_POSSIBLE_MERGE_PATIENTS_SUCCESS: 'FIND_POSSIBLE_MERGE_PATIENTS_SUCCESS',
  START_SEARCHING_MERGE_PATIENTS: 'START_SEARCHING_MERGE_PATIENTS',
  USER_RESPONDED_MERGE_REQUEST: 'USER_RESPONDED_MERGE_REQUEST',
  START_MERGING_PATIENTS: 'START_MERGING_PATIENTS',
  FINISHED_MERGE_PATIENT_PROCESS: 'FINISHED_MERGE_PATIENT_PROCESS',
  MERGE_PATIENT_ERROR: 'MERGE_PATIENT_ERROR',
  GET_FORM_SETTINGS_REQUEST: 'GET_FORM_SETTINGS_REQUEST',
  GET_FORM_SETTINGS_SUCCESS: 'GET_FORM_SETTINGS_SUCCESS',
  // OPEN_FORM_SETTINGS: 'OPEN_FORM_SETTINGS',
  DISCARD_FORM_SETTINGS: 'DISCARD_FORM_SETTINGS',
  TOGGLE_FORM_SETTING_CHECKBOX: 'TOGGLE_FORM_SETTING_CHECKBOX',
  TOGGLE_FORM_PRINT_SETTING_CHECKBOX: 'TOGGLE_FORM_PRINT_SETTING_CHECKBOX',
  SAVE_FORM_SETTINGS_REQUEST: 'SAVE_FORM_SETTINGS_REQUEST',
  SAVE_FORM_SETTINGS_ERROR: 'SAVE_FORM_SETTINGS_ERROR',
  START_SAVING_FORM_SETTINGS: 'START_SAVING_FORM_SETTINGS',
  FETCH_REFERRAL_LIST: 'FETCH_REFERRAL_LIST',
  REFERRAL_LIST_SUCCESS: 'REFERRAL_LIST_SUCCESS',
  REFERRAL_LIST_ERROR: 'REFERRAL_LIST_ERROR',
  GET_PATIENT_FINANCIAL_HISTORY_REQUEST: 'GET_PATIENT_FINANCIAL_HISTORY_REQUEST',
  GET_PATIENT_FINANCIAL_HISTORY_SUCCESS: 'GET_PATIENT_FINANCIAL_HISTORY_SUCCESS',
  GET_PATIENT_FINANCIAL_HISTORY_ERROR: 'GET_PATIENT_FINANCIAL_HISTORY_ERROR',
  LOADING_PATIENT_FINANCIAL_HISTORY: 'LOADING_PATIENT_FINANCIAL_HISTORY',
  CHECK_FOR_DUPLICATE_PATIENT_REQUEST: 'CHECK_FOR_DUPLICATE_PATIENT_REQUEST',
  CHECK_FOR_DUPLICATE_PATIENT_SUCCESS: 'CHECK_FOR_DUPLICATE_PATIENT_SUCCESS',
  CHECK_FOR_DUPLICATE_PATIENT_ERROR: 'CHECK_FOR_DUPLICATE_PATIENT_ERROR',
  CHANGE_CONTACT_FROM_DUPLICATES: 'CHANGE_CONTACT_FROM_DUPLICATES',
  AVATAR_WHATSAPP_FETCH_REQUEST: 'AVATAR_WHATSAPP_FETCH_REQUEST',
  AVATAR_WHATSAPP_FETCH_SUCCESS: 'AVATAR_WHATSAPP_FETCH_SUCCESS',
  AVATAR_WHATSAPP_FETCH_ERROR: 'AVATAR_WHATSAPP_FETCH_ERROR',
  SAVE_PATIENT_AVATAR_WAITING: 'SAVE_PATIENT_AVATAR_WAITING',
  SAVE_PATIENT_AVATAR_FILE: 'SAVE_PATIENT_AVATAR_FILE',
  TOGGLE_PATIENT_AVATAR_MODAL: 'TOGGLE_PATIENT_AVATAR_MODAL',
  FETCH_BIRTHDAYS_LIST_REQUEST: 'FETCH_BIRTHDAYS_LIST_REQUEST',
  FETCH_BIRTHDAYS_LIST_SUCCESS: 'FETCH_BIRTHDAYS_LIST_SUCCESS',
  FETCH_BIRTHDAYS_LIST_ERROR: 'FETCH_BIRTHDAYS_LIST_ERROR',
  FETCHING_BIRTHDAYS_LIST: 'FETCHING_BIRTHDAYS_LIST',
  FETCH_BIRTHDAY_MESSAGE_REQUEST: 'FETCH_BIRTHDAY_MESSAGE_REQUEST',
  FETCH_BIRTHDAY_MESSAGE_SUCCESS: 'FETCH_BIRTHDAY_MESSAGE_SUCCESS',
  FETCH_BIRTHDAY_MESSAGE_ERROR: 'FETCH_BIRTHDAY_MESSAGE_ERROR',
  SAVE_BIRTHDAY_MESSAGE_REQUEST: 'SAVE_BIRTHDAY_MESSAGE_REQUEST',
  SAVE_BIRTHDAY_MESSAGE_SUCCESS: 'SAVE_BIRTHDAY_MESSAGE_SUCCESS',
  SAVE_BIRTHDAY_MESSAGE_ERROR: 'SAVE_BIRTHDAY_MESSAGE_ERROR',
  START_SAVING_BIRTHDAY_MESSAGE: 'START_SAVING_BIRTHDAY_MESSAGE',
  DISCARD_BIRTHDAY_MESSAGE_CHANGES: 'DISCARD_BIRTHDAY_MESSAGE_CHANGES',
  SAVE_DEFAULT_BIRTHDAY_MESSAGE_REQUEST: 'SAVE_DEFAULT_BIRTHDAY_MESSAGE_REQUEST',
  FETCH_UNSAVED_CHANGES_PATIENTS_LIST_REQUEST: 'FETCH_UNSAVED_CHANGES_PATIENTS_LIST_REQUEST',
  FETCH_UNSAVED_CHANGES_PATIENTS_LIST_SUCCESS: 'FETCH_UNSAVED_CHANGES_PATIENTS_LIST_SUCCESS',
  FETCH_UNSAVED_CHANGES_PATIENTS_LIST_ERROR: 'FETCH_UNSAVED_CHANGES_PATIENTS_LIST_ERROR',
  START_FETCHING_UNSAVED_CHANGES_PATIENTS_LIST: 'START_FETCHING_UNSAVED_CHANGES_PATIENTS_LIST',
  CHANGE_CONTACT_TO_SEE_UNSAVED_CHANGES: 'CHANGE_CONTACT_TO_SEE_UNSAVED_CHANGES',
  FETCH_REQUESTING_DOCTOR_LIST_REQUEST: 'FETCH_REQUESTING_DOCTOR_LIST_REQUEST',
  FETCH_REQUESTING_DOCTOR_LIST_SUCCESS: 'FETCH_REQUESTING_DOCTOR_LIST_SUCCESS',
  FETCH_REQUESTING_DOCTOR_LIST_ERROR: 'FETCH_REQUESTING_DOCTOR_LIST_ERROR',
  REMOVE_REFERRAL_HISTORY_REQUEST: 'REMOVE_REFERRAL_HISTORY_REQUEST',
  REMOVE_REFERRAL_HISTORY_SUCCESS: 'REMOVE_REFERRAL_HISTORY_SUCCESS',
  REMOVE_REFERRAL_HISTORY_ERROR: 'REMOVE_REFERRAL_HISTORY_ERROR',
  START_REMOVING_REFERRAL_HISTORY: 'START_REMOVING_REFERRAL_HISTORY',
  REMOVE_REQUESTING_DOCTOR_HISTORY_REQUEST: 'REMOVE_REQUESTING_DOCTOR_HISTORY_REQUEST',
  REMOVE_REQUESTING_DOCTOR_HISTORY_SUCCESS: 'REMOVE_REQUESTING_DOCTOR_HISTORY_SUCCESS',
  REMOVE_REQUESTING_DOCTOR_HISTORY_ERROR: 'REMOVE_REQUESTING_DOCTOR_HISTORY_ERROR',
  START_REMOVING_REQUESTING_DOCTOR_HISTORY: 'START_REMOVING_REQUESTING_DOCTOR_HISTORY',
  SYNC_PATIENTS_AND_PATIENTS_ON_DB: 'SYNC_PATIENTS_AND_PATIENTS_ON_DB',
  GET_PATIENTS_LOGS_REQUEST: 'GET_PATIENTS_LOGS_REQUEST',
  GET_PATIENTS_LOGS_SUCCESS: 'GET_PATIENTS_LOGS_SUCCESS',
  GET_PATIENTS_LOGS_ERROR: 'GET_PATIENTS_LOGS_ERROR',
  SEARCH_SPOUSE_PROFILE_REQUEST: 'SEARCH_SPOUSE_PROFILE_REQUEST',
  SEARCH_SPOUSE_PROFILE_SUCCESS: 'SEARCH_SPOUSE_PROFILE_SUCCESS',
  FETCH_ALERT_PATIENTS: 'FETCH_ALERT_PATIENTS',
  FETCH_ALERT_PATIENTS_SUCCESS: 'FETCH_ALERT_PATIENTS_SUCCESS',
  FETCH_ALERT_PATIENTS_LOADING: 'FETCH_ALERT_PATIENTS_LOADING',
  CLEAR_PATIENT_ALERTS: 'CLEAR_PATIENT_ALERTS',
  // getPatients: () => ({
  //   type: contactActions.GET_PATIENTS_REQUEST,
  // }),
  savePatient: (contact, appointment = false, appointmentType = false) => ({
    type: contactActions.SAVE_PATIENT_REQUEST,
    payload: {
      contact,
      appointment,
      appointmentType,
      attemptsLeft: 3,
    },
  }),
  discardPatientChanges: () => ({
    type: contactActions.DISCARD_PATIENT_CHANGES,
  }),
  changeContact: (id) => ({
    type: contactActions.CHANGE_CONTACT,
    id,
  }),
  addContact: () => ({
    type: contactActions.ADD_CONTACT_REQUEST,
  }),
  editContact: (newContact) => (dispatch, getState) => {
    // const contacts = getState().Contacts.contacts;
    const contacts = getState().Contacts.patients;
    const newContacts = [];
    contacts.forEach((contact) => {
      if (contact.id === newContact.id) {
        newContacts.push(newContact);
      } else {
        newContacts.push(contact);
      }
    });
    dispatch({
      type: contactActions.EDIT_CONTACT,
      // contacts: newContacts.sort(ascendingSort),
      patients: newContacts.sort(ascendingSort),
    });
  },
  deleteContact: (id) => ((dispatch) => {
    dispatch({
      type: contactActions.DELETE_CONTACT,
      payload: id,
    });
  }),
  recoverContact: (contact) => ((dispatch) => {
    dispatch({
      type: contactActions.RECOVER_CONTACT,
      payload: contact,
    });
  }),
  viewChange: (view) => ({
    type: contactActions.EDIT_VIEW,
    view,
  }),
  togglePatientAvatarModal: () => ({
    type: contactActions.TOGGLE_PATIENT_AVATAR_MODAL,
  }),
  fetchReferralList: () => ({
    type: contactActions.FETCH_REFERRAL_LIST,
  }),
  fetchPatientData: (id, appointment = null) => ({
    type: contactActions.FETCH_PATIENT_DATA,
    payload: {
      id,
      appointment,
    },
  }),
  searchPatients: (search, append = false, requestMode = 'active') => ({
    type: contactActions.SEARCH_PATIENTS_REQUEST,
    payload: {
      search,
      append,
      requestMode,
    },
  }),
  searchZipCode: (code) => ({
    type: contactActions.SEARCH_ZIP_CODE_REQUEST,
    payload: code,
  }),
  patientHistoryFetch: (id = false) => ({
    type: contactActions.PATIENT_HISTORY_REQUEST,
    payload: id,
  }),
  resetSearchPatientsSettings: (input) => ({
    type: contactActions.RESET_SEARCH_PATIENTS_SETTINGS,
    payload: input,
  }),
  mergePatientResponse: (value) => ({
    type: contactActions.USER_RESPONDED_MERGE_REQUEST,
    payload: value,
  }),
  getFormSettings: () => ({
    type: contactActions.GET_FORM_SETTINGS_REQUEST,
    payload: {
      closeForm: false,
    },
  }),
  closeFormSettings: () => ({
    type: contactActions.DISCARD_FORM_SETTINGS,
  }),
  toggleFormSettingCheckbox: (attribute, value) => ({
    type: contactActions.TOGGLE_FORM_SETTING_CHECKBOX,
    payload: {
      attribute,
      value,
    },
  }),
  toggleFormPrintSettingCheckbox: (attribute, value) => ({
    type: contactActions.TOGGLE_FORM_PRINT_SETTING_CHECKBOX,
    payload: {
      attribute,
      value,
    },
  }),
  saveFormSettings: () => ({
    type: contactActions.SAVE_FORM_SETTINGS_REQUEST,
  }),
  getPatientFinancialHistory: () => ({
    type: contactActions.GET_PATIENT_FINANCIAL_HISTORY_REQUEST,
  }),
  checkForDuplicatePatient: (cpf, name) => ({
    type: contactActions.CHECK_FOR_DUPLICATE_PATIENT_REQUEST,
    payload: {
      cpf,
      name,
    },
  }),
  changeContactFromDuplicates: (id, list) => ({
    type: contactActions.CHANGE_CONTACT_FROM_DUPLICATES,
    payload: {
      id,
      list,
    },
  }),
  getWhatsappAvatar: () => ({
    type: contactActions.AVATAR_WHATSAPP_FETCH_REQUEST,
  }),
  savePatientAvatarAsFile: (file) => ({
    type: contactActions.SAVE_PATIENT_AVATAR_FILE,
    payload: file,
  }),
  getBirthdaysList: (searchMode, dateValue) => ({
    type: contactActions.FETCH_BIRTHDAYS_LIST_REQUEST,
    payload: {
      searchMode,
      dateValue,
    },
  }),
  getBirthdayMessage: () => ({
    type: contactActions.FETCH_BIRTHDAY_MESSAGE_REQUEST,
  }),
  saveBirthdayMessage: (value) => ({
    type: contactActions.SAVE_BIRTHDAY_MESSAGE_REQUEST,
    payload: value,
  }),
  saveDefaultBirthdayMessage: () => ({
    type: contactActions.SAVE_BIRTHDAY_MESSAGE_REQUEST,
    payload: {
      useDefault: true,
    },
  }),
  discardBirthdayMessageChanges: () => ({
    type: contactActions.DISCARD_BIRTHDAY_MESSAGE_CHANGES,
  }),
  fetchUnsavedChangesPatientsList: (savedAllChanges) => ({
    type: contactActions.FETCH_UNSAVED_CHANGES_PATIENTS_LIST_REQUEST,
    payload: savedAllChanges,
  }),
  fetchAlertPatientsListRequest: (patientId) => ({
    type: contactActions.FETCH_ALERT_PATIENTS,
    payload: patientId,
  }),
  clearPatientAlerts: () => ({
    type: contactActions.CLEAR_PATIENT_ALERTS,
  }),
  changeContactToSeeUnsavedChanges: (patientId, patientsArr) => ({
    type: contactActions.CHANGE_CONTACT_TO_SEE_UNSAVED_CHANGES,
    payload: {
      patientId,
      patientsArr,
    },
  }),
  fetchRequestingDoctorList: () => ({
    type: contactActions.FETCH_REQUESTING_DOCTOR_LIST_REQUEST,
  }),
  removeReferralHistory: (item) => ({
    type: contactActions.REMOVE_REFERRAL_HISTORY_REQUEST,
    payload: item,
  }),
  removeRequestingDoctorHistory: (item) => ({
    type: contactActions.REMOVE_REQUESTING_DOCTOR_HISTORY_REQUEST,
    payload: item,
  }),
  searchPatientSpouseProfile: (cpf) => ({
    type: contactActions.SEARCH_SPOUSE_PROFILE_REQUEST,
    payload: cpf,
  }),
};
export default contactActions;
