import _ from 'lodash';
import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  financialSettings: {},
  // invoiceAutomation: {},
  operationsList: {},
  finalizedAppointmentsList: {},
  costCenterProfiles: {},
  selectedCostCenterProfile: {},
  selectedCostCenterProfileArr: {},
  balance: {},
  cashBalance: {},
  // manualOpsList: {},
  accrualBalanceHistory: {},
  cashBalanceHistory: {},
  monthIncome: {},
  cashMonthIncome: {},
  nextTransactionsList: {},
  finalizedAppointmentListenerTime: 1,
  manualOpsListenerTime: 1,
  cardTaxes: [],
};

export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    // case actions.GET_FINANCIAL_SETTINGS_SUCCESS:
    //   return {
    //     ...state,
    //     invoiceAutomation: {
    //       ...state.invoiceAutomation,
    //       [action.payload.address]: action.payload.invoiceAutomation,
    //     },
    //   };
    case actions.GET_FINANCIAL_SETTINGS_SUCCESS:
      return {
        ...state,
        financialSettings: {
          ...state.financialSettings,
          [action.payload.address]: action.payload.financialSettings,
        },
      };
    case actions.CHECKING_FINANCIAL_PAYMENTS:
      return {
        ...state,
        checkingFinancialPayments: true,
      };
    case actions.CHECK_FINANCIAL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        checkingFinancialPayments: false,
      };
    case actions.CHECK_FINANCIAL_TRANSACTIONS_ERROR:
      return {
        ...state,
        checkingFinancialPayments: null,
      };
    case actions.GET_BALANCE_SUCCESS:
      return {
        ...state,
        // balance: action.payload.balance,
        balance: {
          ...state.balance,
          [action.payload.costCenterProfile]: action.payload.balance,
        },
        // loadingBalance: false,
      };
    case actions.GET_BALANCE_ERROR:
      return {
        ...state,
        // balance: 0,
        balance: {},
        // loadingBalance: null,
      };
    case actions.GET_CASH_BALANCE_SUCCESS:
      return {
        ...state,
        // cashBalance: action.payload.balance,
        cashBalance: {
          ...state.cashBalance,
          [action.payload.costCenterProfile]: action.payload.balance,
        },
        // loadingBalance: false,
      };
    case actions.GET_CASH_BALANCE_ERROR:
      return {
        ...state,
        // cashBalance: 0,
        cashBalance: {},
        // loadingBalance: null,
      };
    case actions.RESET_BALANCE:
      return {
        ...state,
        balance: {},
      };
    case actions.RESET_CASH_BALANCE:
      return {
        ...state,
        cashBalance: {},
      };
    case actions.GET_OPERATIONS_LIST_SUCCESS:
      return {
        ...state,
        operationsList: {
          ...state.operationsList,
          [action.payload.address]: _.cloneDeep(action.payload.operationsList),
        },
        updatingOperationsList: false,
      };
    case actions.GET_OPERATIONS_LIST_ERROR:
      return {
        ...state,
        operationsList: {
          ...state.operationsList,
          [action.payload.address]: [],
        },
        updatingOperationsList: null,
      };
    case actions.OPERATIONS_LIST_WAITING:
      return {
        ...state,
        updatingOperationsList: true,
      };
    case actions.GET_BANK_STATEMENT_SUCCESS:
      return {
        ...state,
        bankStatementList: _.cloneDeep(action.payload.bankStatementList),
        // bankStatementList: {
        //   ...state.bankStatementList,
        //   [action.payload.costCenterProfile]: action.payload.bankStatementList,
        // },
        loadingBankStatement: false,
      };
    case actions.GET_BANK_STATEMENT_ERROR:
      return {
        ...state,
        bankStatementList: [],
        loadingBankStatement: null,
      };
    case actions.FETCHING_BANK_STATEMENT:
      return {
        ...state,
        loadingBankStatement: true,
      };
    case actions.GET_COST_CENTER_SUCCESS:
      return {
        ...state,
        costCenterProfiles: {
          ...state.costCenterProfiles,
          [action.payload.address]: _.cloneDeep(action.payload.costCenterProfiles),
        },
        updatingCostCenter: false,
        removingCostCenter: false,
      };
    case actions.SELECT_COST_CENTER_PROFILE:
      return {
        ...state,
        selectedCostCenterProfile: {
          ...state.selectedCostCenterProfile,
          [action.payload.address]: action.payload.selectedCostCenterProfile,
        },
        bankStatementList: undefined,
      };
    case actions.SELECT_COST_CENTER_PROFILE_ARR:
      return {
        ...state,
        selectedCostCenterProfileArr: {
          ...state.selectedCostCenterProfileArr,
          [action.payload.address]: action.payload.selectedCostCenterProfileArr,
        },
        bankStatementList: undefined,
      };
    case actions.COST_CENTER_FETCH_OR_UPDATING_WAITING:
      return {
        ...state,
        updatingCostCenter: true,
      };
    case actions.CREATE_COST_CENTER_PROFILE_ERROR:
      return {
        ...state,
        updatingCostCenter: null,
      };
    case actions.REMOVING_COST_CENTER_PROFILE:
      return {
        ...state,
        removingCostCenter: action.payload,
      };
    case actions.REMOVE_COST_CENTER_PROFILE_ERROR:
      return {
        ...state,
        removingCostCenter: null,
      };
    case actions.GET_FINANCIAL_CONTACTS_SUCCESS:
      return {
        ...state,
        financialContacts: action.payload,
      };
    case actions.CREATE_MANUAL_INCOME_SUCCESS:
      return {
        ...state,
        updatingManualTransaction: false,
      };
    case actions.CREATE_MANUAL_INCOME_ERROR:
      return {
        ...state,
        updatingManualTransaction: null,
      };
    case actions.CREATING_MANUAL_OPS:
      return {
        ...state,
        updatingManualTransaction: true,
      };
    case actions.CREATE_MANUAL_EXPENSE_SUCCESS:
      return {
        ...state,
        updatingManualTransaction: false,
      };
    case actions.CREATE_MANUAL_EXPENSE_ERROR:
      return {
        ...state,
        updatingManualTransaction: null,
      };
    case actions.GET_FINALIZED_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        // finalizedAppointmentsList: {
        //   ...state.finalizedAppointmentsList,
        //   [action.payload.address]: _.cloneDeep(action.payload.finalizedAppointmentsArr),
        // },
        finalizedAppointmentsArr: _.cloneDeep(action.payload.finalizedAppointmentsArr),
        // finalizedAppointments: _.cloneDeep(action.payload.finalizedAppointmentsObj),
        loadingFinalizedAppointments: false,
      };
    case actions.FETCHING_FINALIZED_APPOINTMENTS_LIST:
      return {
        ...state,
        loadingFinalizedAppointments: true,
      };
    case actions.GET_FINALIZED_APPOINTMENTS_ERROR:
      return {
        ...state,
        loadingFinalizedAppointments: null,
      };
    case actions.RESET_FINALIZED_APPOINTMENTS:
      return {
        ...state,
        finalizedAppointmentsList: {},
        finalizedAppointments: undefined,
        finalizedAppointmentsArr: undefined,
      };
    case actions.UPDATE_FINALIZED_APPOINTMENT_SUCCESS:
      return {
        ...state,
        updatingFinalizedAppointment: false,
      };
    case actions.UPDATING_FINALIZED_APPOINTMENT:
      return {
        ...state,
        updatingFinalizedAppointment: true,
      };
    case actions.UPDATE_FINALIZED_APPOINTMENT_ERROR:
      return {
        ...state,
        updatingFinalizedAppointment: null,
      };
    case actions.REMOVING_FINALIZED_APPOINTMENT:
      return {
        ...state,
        removingFinalizedAppointment: action.payload,
      };
    case actions.REMOVE_FINALIZED_APPOINTMENT_SUCCESS:
      return {
        ...state,
        removingFinalizedAppointment: false,
      };
    case actions.REMOVE_FINALIZED_APPOINTMENT_ERROR:
      return {
        ...state,
        removingFinalizedAppointment: null,
      };
    case actions.FETCHING_MANUAL_OPS_LIST:
      return {
        ...state,
        fetchingManualOps: true,
      };
    case actions.GET_MANUAL_OPS_SUCCESS:
      return {
        ...state,
        // manualOpsList: {
        //   ...state.manualOpsList,
        //   [action.payload.address]: _.cloneDeep(action.payload.manualOpsList),
        // },
        manualOpsArr: _.cloneDeep(action.payload.manualOpsArr),
        // manualOps: _.cloneDeep(action.payload.manualOpsObj),
        fetchingManualOps: false,
      };
    case actions.GET_MANUAL_OPS_ERROR:
      return {
        ...state,
        fetchingManualOps: null,
      };
    case actions.RESET_MANUAL_OPS:
      return {
        ...state,
        manualOps: undefined,
        manualOpsArr: undefined,
      };
    case actions.UPDATING_MANUAL_OPS:
      return {
        ...state,
        updatingManualOps: true,
      };
    case actions.UPDATE_MANUAL_OPS_SUCCESS:
      return {
        ...state,
        updatingManualOps: false,
      };
    case actions.UPDATE_MANUAL_OPS_ERROR:
      return {
        ...state,
        updatingManualOps: null,
      };
    case actions.REMOVING_MANUAL_OPS:
      return {
        ...state,
        removingManualOps: action.payload,
      };
    case actions.REMOVE_MANUAL_OPS_SUCCESS:
      return {
        ...state,
        removingManualOps: false,
      };
    case actions.REMOVE_MANUAL_OPS_ERROR:
      return {
        ...state,
        removingManualOps: null,
      };
    case actions.GET_BALANCE_HISTORY_SUCCESS:
      return {
        ...state,
        // accrualBalanceHistory: {
        //   ...state.accrualBalanceHistory,
        //   [action.payload.address]: _.cloneDeep(action.payload.accrualBalanceHistory),
        // },
        // cashBalanceHistory: {
        //   ...state.cashBalanceHistory,
        //   [action.payload.address]: _.cloneDeep(action.payload.cashBalanceHistory),
        // },
        accrualBalanceHistory: {
          ...state.accrualBalanceHistory,
          [action.payload.costCenterProfile]: _.cloneDeep(action.payload.accrualBalanceHistory),
        },
        cashBalanceHistory: {
          ...state.cashBalanceHistory,
          [action.payload.costCenterProfile]: _.cloneDeep(action.payload.cashBalanceHistory),
        },
        loadingBalanceHistory: false,
      };
    case actions.RESET_BALANCE_HISTORY:
      return {
        ...state,
        accrualBalanceHistory: {},
        cashBalanceHistory: {},
        loadingBalanceHistory: false,
      };
    case actions.FETCHING_BALANCE_HISTORY:
      return {
        ...state,
        loadingBalanceHistory: true,
      };
    case actions.GET_BALANCE_HISTORY_ERROR:
      return {
        ...state,
        loadingBalanceHistory: null,
      };
    case actions.GET_NEXT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        // nextTransactionsList: _.cloneDeep(action.payload.nextTransactionsList),
        nextTransactionsList: {
          ...state.nextTransactionsList,
          [action.payload.costCenterProfile]: _.cloneDeep(action.payload.nextTransactionsList),
        },
        loadingNextTransactions: false,
      };
    case actions.GET_NEXT_TRANSACTIONS_ERROR:
      return {
        ...state,
        nextTransactionsList: [],
        loadingNextTransactions: null,
      };
    case actions.FETCHING_NEXT_TRANSACTIONS:
      return {
        ...state,
        loadingNextTransactions: true,
      };
    case actions.FETCHING_MONTH_INCOME_VALUES:
      return {
        ...state,
        loadingMonthIncome: true,
      };
    case actions.GET_MONTH_INCOME_SUCCESS:
      return {
        ...state,
        // monthIncome: {
        //   ...state.monthIncome,
        //   [action.payload.address]: {
        //     monthIncomeValue: action.payload.monthIncomeValue,
        //     monthExpenseValue: action.payload.monthExpenseValue,
        //   },
        // },
        // cashMonthIncome: {
        //   ...state.cashMonthIncome,
        //   [action.payload.address]: {
        //     monthIncomeValue: action.payload.cashMonthIncomeValue,
        //     monthExpenseValue: action.payload.cashMonthExpenseValue,
        //   },
        // },
        monthIncome: {
          ...state.monthIncome,
          [action.payload.costCenterProfile]: {
            monthIncomeValue: action.payload.monthIncomeValue,
            monthExpenseValue: action.payload.monthExpenseValue,
          },
        },
        cashMonthIncome: {
          ...state.cashMonthIncome,
          [action.payload.costCenterProfile]: {
            monthIncomeValue: action.payload.cashMonthIncomeValue,
            monthExpenseValue: action.payload.cashMonthExpenseValue,
          },
        },
        loadingMonthIncome: false,
      };
    case actions.GET_MONTH_INCOME_ERROR:
      return {
        ...state,
        monthIncome: {
          ...state.monthIncome,
          [action.payload.address]: {
            monthIncomeValue: 0,
            monthExpenseValue: 0,
          },
        },
        loadingMonthIncome: null,
      };
    // case actions.GET_TRANSACTIONS_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     transactionsList: action.payload.responseArr,
    //   };
    case actions.CHANGE_FINALIZED_APPOINTMENT_LISTENER_TIMER:
      return {
        ...state,
        finalizedAppointmentListenerTime: action.payload.value,
        finalizedAppointmentListenerTimeRange: action.payload.dateRange,
        finalizedAppointmentListenerCustomTime: action.payload.customValue,
      };
    case actions.CHANGE_MANUAL_OPS_LISTENER_TIMER:
      return {
        ...state,
        manualOpsListenerTime: action.payload.value,
        manualOpsListenerTimeRange: action.payload.dateRange,
        manualOpsListenerCustomTime: action.payload.customValue,
      };
    case actions.LOADING_CASH_PAYMENT:
      return {
        ...state,
        loadingCashPayment: action.payload,
      };
    case actions.CONFIRM_CASH_PAYMENT_SUCCESS:
      return {
        ...state,
        loadingCashPayment: false,
      };
    case actions.CONFIRM_CASH_PAYMENT_ERROR:
      return {
        ...state,
        loadingCashPayment: null,
      };
    case actions.FETCHING_CARD_TAXES:
      return {
        ...state,
        loadingCardTaxes: true,
      };
    case actions.GET_CARD_TAXES_SUCCESS:
      return {
        ...state,
        cardTaxes: action.payload.cardTaxesArr,
        loadingCardTaxes: false,
        savingCardTaxes: false,
        removingCardTaxes: false,
      };
    case actions.GET_CARD_TAXES_ERROR:
      return {
        ...state,
        loadingCardTaxes: null,
      };
    case actions.SAVING_CARD_TAXES:
      return {
        ...state,
        savingCardTaxes: true,
      };
    case actions.REMOVING_CARD_SETTINGS:
      return {
        ...state,
        removingCardTaxes: true,
      };
    default:
      return state;
  }
}
