const actions = {
  REQUESTS_FETCH: 'REQUESTS_FETCH',
  CREATE_NEW_AGENDA_ID_REQUEST: 'CREATE_NEW_AGENDA_ID_REQUEST',
  CREATE_NEW_AGENDA_ID_SUCCESS: 'CREATE_NEW_AGENDA_ID_SUCCESS',
  CREATE_NEW_AGENDA_ID_ERROR: 'CREATE_NEW_AGENDA_ID_ERROR',
  CREATING_NEW_AGENDA_ID: 'CREATING_NEW_AGENDA_ID',
  SAVE_AGENDA_SETTINGS_REQUEST: 'SAVE_AGENDA_SETTINGS_REQUEST',
  SAVE_AGENDA_SETTINGS_SUCCESS: 'SAVE_AGENDA_SETTINGS_SUCCESS',
  SAVE_AGENDA_SETTINGS_ERROR: 'SAVE_AGENDA_SETTINGS_ERROR',
  SAVING_AGENDA_SETTINGS: 'SAVING_AGENDA_SETTINGS',
  DELETE_AGENDA_REQUEST: 'DELETE_AGENDA_REQUEST',
  DELETE_AGENDA_SUCCESS: 'DELETE_AGENDA_SUCCESS',
  DELETE_AGENDA_ERROR: 'DELETE_AGENDA_ERROR',
  DELETING_AGENDA: 'DELETING_AGENDA',
  RESET_AGENDA: 'RESET_AGENDA',
  SELECT_AGENDA: 'SELECT_AGENDA',
  RESET_CONFIRMED: 'RESET_CONFIRMED',
  RESET_PAST_CONFIRMED: 'RESET_PAST_CONFIRMED',
  RESET_CANCELED: 'RESET_CANCELED',
  RESET_PAST_CANCELED: 'RESET_PAST_CANCELED',
  FETCH_AGENDAS_FROM_FIRESTORE_REQUEST: 'FETCH_AGENDAS_FROM_FIRESTORE_REQUEST',
  FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS: 'FETCH_AGENDAS_FROM_FIRESTORE_SUCCESS',
  FETCH_AGENDAS_FROM_FIRESTORE_ERROR: 'FETCH_AGENDAS_FROM_FIRESTORE_ERROR',
  FETCHING_AGENDAS: 'FETCHING_AGENDAS',
  FETCHING_PAST_CONFIRMED: 'FETCHING_PAST_CONFIRMED',
  CONFIRMED_FETCH_SUCCESS: 'CONFIRMED_FETCH_SUCCESS',
  PAST_CONFIRMED_FETCH_SUCCESS: 'PAST_CONFIRMED_FETCH_SUCCESS',
  PENDING_FETCH_SUCCESS: 'PENDING_FETCH_SUCCESS',
  ALL_PENDING_FETCH_SUCCESS: 'ALL_PENDING_FETCH_SUCCESS',
  REJECTED_FETCH_SUCCESS: 'REJECTED_FETCH_SUCCESS',
  ALL_REJECTED_FETCH_SUCCESS: 'ALL_REJECTED_FETCH_SUCCESS',
  CANCELED_FETCH_SUCCESS: 'CANCELED_FETCH_SUCCESS',
  PAST_CANCELED_FETCH_SUCCESS: 'PAST_CANCELED_FETCH_SUCCESS',
  ACCEPTED_FETCH_SUCCESS: 'ACCEPTED_FETCH_SUCCESS',
  ALL_ACCEPTED_FETCH_SUCCESS: 'ALL_ACCEPTED_FETCH_SUCCESS',
  PATIENT_INFO_FETCH_SUCCESS: 'PATIENT_INFO_FETCH_SUCCESS',
  CONFIRM_REQUEST: 'CONFIRM_REQUEST',
  CONFIRM_REQUEST_SUCCESS: 'CONFIRM_REQUEST_SUCCESS',
  CONFIRM_REQUEST_FAIL: 'CONFIRM_REQUEST_FAIL',
  START_UPDATING_REQUEST: 'START_UPDATING_REQUEST',
  ABORT_CONFIRM_REQUEST: 'ABORT_CONFIRM_REQUEST',
  REJECT_REQUEST: 'REJECT_REQUEST',
  REJECT_REQUEST_SUCCESS: 'REJECT_REQUEST_SUCCESS',
  REJECT_REQUEST_FAIL: 'REJECT_REQUEST_FAIL',
  REJECTING_REQUEST: 'REJECTING_REQUEST',
  EDIT_APPOINTMENT_TIME_REQUEST: 'EDIT_APPOINTMENT_TIME_REQUEST',
  EDIT_APPOINTMENT_TIME_SUCCESS: 'EDIT_APPOINTMENT_TIME_SUCCESS',
  EDIT_APPOINTMENT_TIME_ERROR: 'EDIT_APPOINTMENT_TIME_ERROR',
  EDIT_APPOINTMENT_NOTES_REQUEST: 'EDIT_APPOINTMENT_NOTES_REQUEST',
  EDIT_APPOINTMENT_NOTES_SUCCESS: 'EDIT_APPOINTMENT_NOTES_SUCCESS',
  EDIT_APPOINTMENT_NOTES_ERROR: 'EDIT_APPOINTMENT_NOTES_ERROR',
  SAVING_APPOINTMENT_NOTES: 'SAVING_APPOINTMENT_NOTES',
  SET_MANUAL_CONFIRMATION_MESSAGE_SENT_REQUEST: 'SET_MANUAL_CONFIRMATION_MESSAGE_SENT_REQUEST',
  SET_MANUAL_CONFIRMATION_MESSAGE_SENT_SUCCESS: 'SET_MANUAL_CONFIRMATION_MESSAGE_SENT_SUCCESS',
  SET_MANUAL_CONFIRMATION_MESSAGE_SENT_ERROR: 'SET_MANUAL_CONFIRMATION_MESSAGE_SENT_ERROR',
  START_SAVING_APPOINTMENT_EDIT: 'START_SAVING_APPOINTMENT_EDIT',
  FINALIZE_APPOINTMENT_REQUEST: 'FINALIZE_APPOINTMENT_REQUEST',
  FINALIZE_APPOINTMENT_SUCCESS: 'FINALIZE_APPOINTMENT_SUCCESS',
  FINALIZE_APPOINTMENT_ERROR: 'FINALIZE_APPOINTMENT_ERROR',
  START_FINALIZING_APPOINTMENT: 'START_FINALIZING_APPOINTMENT',
  PATIENT_CONFIRMED_APPOINTMENT_REQUEST: 'PATIENT_CONFIRMED_APPOINTMENT_REQUEST',
  PATIENT_CONFIRMED_APPOINTMENT_SUCCESS: 'PATIENT_CONFIRMED_APPOINTMENT_SUCCESS',
  PATIENT_CONFIRMED_APPOINTMENT_ERROR: 'PATIENT_CONFIRMED_APPOINTMENT_ERROR',
  START_CONFIRMING_PATIENT_REQUEST: 'START_CONFIRMING_PATIENT_REQUEST',
  CANCEL_APPOINTMENT_REQUEST: 'CANCEL_APPOINTMENT_REQUEST',
  CANCEL_APPOINTMENT_SUCCESS: 'CANCEL_APPOINTMENT_SUCCESS',
  CANCEL_APPOINTMENT_ERROR: 'CANCEL_APPOINTMENT_ERROR',
  SET_SURGERY_REQUEST: 'SET_SURGERY_REQUEST',
  SET_SURGERY_SUCCESS: 'SET_SURGERY_SUCCESS',
  SET_SURGERY_ERROR: 'SET_SURGERY_ERROR',
  START_SET_SURGERY: 'START_SET_SURGERY',
  APPOINTMENT_DOES_NOT_EXIST: 'APPOINTMENT_DOES_NOT_EXIST',
  START_CANCELING_APPOINTMENT: 'START_CANCELING_APPOINTMENT',
  MANUALLY_SET_NEW_APPOINTMENT_REQUEST: 'MANUALLY_SET_NEW_APPOINTMENT_REQUEST',
  MANUALLY_SET_NEW_APPOINTMENT_SUCCESS: 'MANUALLY_SET_NEW_APPOINTMENT_SUCCESS',
  MANUALLY_SET_NEW_APPOINTMENT_FAIL: 'MANUALLY_SET_NEW_APPOINTMENT_FAIL',
  EDIT_WHOLE_APPOINTMENT_REQUEST: 'EDIT_WHOLE_APPOINTMENT_REQUEST',
  EDIT_WHOLE_APPOINTMENT_SUCCESS: 'EDIT_WHOLE_APPOINTMENT_SUCCESS',
  EDIT_WHOLE_APPOINTMENT_ERROR: 'EDIT_WHOLE_APPOINTMENT_ERROR',
  START_EDITING_APPOINTMENT_REQUEST: 'START_EDITING_APPOINTMENT_REQUEST',
  FAILED_TO_SEND_INITIAL_MESSAGE_NOTIFICATION: 'FAILED_TO_SEND_INITIAL_MESSAGE_NOTIFICATION',
  SETTING_NEW_APPOINTMENT: 'SETTING_NEW_APPOINTMENT',
  BLOCK_DAYS_REQUEST: 'BLOCK_DAYS_REQUEST',
  BLOCK_DAYS_SUCCESS: 'BLOCK_DAYS_SUCCESS',
  BLOCK_DAYS_ERROR: 'BLOCK_DAYS_ERROR',
  START_BLOCKING_DAYS: 'START_BLOCKING_DAYS',
  UNBLOCK_DAY_REQUEST: 'UNBLOCK_DAY_REQUEST',
  UNBLOCK_DAY_SUCCESS: 'UNBLOCK_DAY_SUCCESS',
  UNBLOCK_MULTIPLE_DAYS_REQUEST: 'UNBLOCK_MULTIPLE_DAYS_REQUEST',
  UNBLOCK_DAY_ERROR: 'UNBLOCK_DAY_ERROR',
  START_UNBLOCKING_DAY: 'START_UNBLOCKING_DAY',
  START_UNBLOCKING_MULTIPLE_DAYS: 'START_UNBLOCKING_MULTIPLE_DAYS',
  BLOCK_TIME_REQUEST: 'BLOCK_TIME_REQUEST',
  BLOCK_TIME_SUCCESS: 'BLOCK_TIME_SUCCESS',
  BLOCK_TIME_ERROR: 'BLOCK_TIME_ERROR',
  START_BLOCKING_TIME: 'START_BLOCKING_TIME',
  ARRIVED_TIME_REQUEST: 'ARRIVED_TIME_REQUEST',
  ARRIVED_TIME_SUCCESS: 'ARRIVED_TIME_SUCCESS',
  ARRIVED_TIME_ERROR: 'ARRIVED_TIME_ERROR',
  START_SAVING_ARRIVED_TIME: 'START_SAVING_ARRIVED_TIME',
  MESSAGE_READ_REQUEST: 'MESSAGE_READ_REQUEST',
  MESSAGE_READ_SUCCESS: 'MESSAGE_READ_SUCCESS',
  MESSAGE_READ_ERROR: 'MESSAGE_READ_ERROR',
  START_SAVING_PATIENT_MISSED_APPOINTMENT: 'START_SAVING_PATIENT_MISSED_APPOINTMENT',
  PATIENT_MISSED_APPOINTMENT_REQUEST: 'PATIENT_MISSED_APPOINTMENT_REQUEST',
  PATIENT_MISSED_APPOINTMENT_SUCCESS: 'PATIENT_MISSED_APPOINTMENT_SUCCESS',
  PATIENT_MISSED_APPOINTMENT_ERROR: 'PATIENT_MISSED_APPOINTMENT_ERROR',
  START_PATIENT_BEING_ATTENDING: 'START_PATIENT_BEING_ATTENDING',
  PATIENT_BEING_ATTENDING_REQUEST: 'PATIENT_BEING_ATTENDING_REQUEST',
  PATIENT_BEING_ATTENDING_SUCCESS: 'PATIENT_BEING_ATTENDING_SUCCESS',
  PATIENT_BEING_ATTENDING_ERROR: 'PATIENT_BEING_ATTENDING_ERROR',
  START_SAVING_MESSAGE_READ: 'START_SAVING_MESSAGE_READ',
  CLEAR_DID_READ_MESSAGE: 'CLEAR_DID_READ_MESSAGE',
  BUILD_FREE_SCHEDULE_SUCCESS: 'BUILD_FREE_SCHEDULE_SUCCESS',
  CLEAR_APPOINTMENT_STATUS_REQUEST: 'CLEAR_APPOINTMENT_STATUS_REQUEST',
  CLEAR_APPOINTMENT_STATUS_SUCCESS: 'CLEAR_APPOINTMENT_STATUS_SUCCESS',
  CLEAR_APPOINTMENT_STATUS_ERROR: 'CLEAR_APPOINTMENT_STATUS_ERROR',
  START_CLEARING_APPOINTMENT_STATUS: 'START_CLEARING_APPOINTMENT_STATUS',
  UPDATE_APPOINTMENT_VALUE_REQUEST: 'UPDATE_APPOINTMENT_VALUE_REQUEST',
  UPDATE_APPOINTMENT_VALUE_SUCCESS: 'UPDATE_APPOINTMENT_VALUE_SUCCESS',
  UPDATE_APPOINTMENT_VALUE_ERROR: 'UPDATE_APPOINTMENT_VALUE_ERROR',
  START_UPDATING_APPOINTMENT_VALUE: 'START_UPDATING_APPOINTMENT_VALUE',
  NOTIFY_NEW_APPOINTMENT_TIME_REQUEST: 'NOTIFY_NEW_APPOINTMENT_TIME_REQUEST',
  NOTIFY_NEW_BATCHED_APPOINTMENT_TIME_REQUEST: 'NOTIFY_NEW_BATCHED_APPOINTMENT_TIME_REQUEST',
  // FOUND_POSSIBLE_MERGE_PATIENTS: 'FOUND_POSSIBLE_MERGE_PATIENTS',
  // USER_RESPONDED_MERGE_REQUEST: 'USER_RESPONDED_MERGE_REQUEST',
  GET_PATIENT_APPOINTMENTS_REQUEST: 'GET_PATIENT_APPOINTMENTS_REQUEST',
  GET_PATIENT_APPOINTMENTS_SUCCESS: 'GET_PATIENT_APPOINTMENTS_SUCCESS',
  GET_PATIENT_APPOINTMENTS_ERROR: 'GET_PATIENT_APPOINTMENTS_ERROR',
  RESET_PATIENT_APPOINTMENTS: 'RESET_PATIENT_APPOINTMENTS',
  DOWNLOADING_PATIENT_APPOINTMENTS: 'DOWNLOADING_PATIENT_APPOINTMENTS',
  CHANGE_LISTENER_PARAM: 'CHANGE_LISTENER_PARAM',
  CHANGE_PAST_LISTENER_PARAM: 'CHANGE_PAST_LISTENER_PARAM',
  SET_NEW_AGENDA_LISTENER: 'SET_NEW_AGENDA_LISTENER',
  SET_NEW_PAST_AGENDA_LISTENER: 'SET_NEW_PAST_AGENDA_LISTENER',
  SET_NEW_AGENDA_CANCELED_LISTENER: 'SET_NEW_AGENDA_CANCELED_LISTENER',
  SET_NEW_PAST_AGENDA_CANCELED_LISTENER: 'SET_NEW_PAST_AGENDA_CANCELED_LISTENER',
  LOADING_PAST_AGENDA: 'LOADING_PAST_AGENDA',
  FINALIZE_APPOINTMENT_WITH_INVOICE_REQUEST: 'FINALIZE_APPOINTMENT_WITH_INVOICE_REQUEST',
  FINALIZE_APPOINTMENT_WITH_INVOICE_SUCCESS: 'FINALIZE_APPOINTMENT_WITH_INVOICE_SUCCESS',
  FINALIZE_APPOINTMENT_WITH_INVOICE_ERROR: 'FINALIZE_APPOINTMENT_WITH_INVOICE_ERROR',
  START_FINALIZE_APPOINTMENT_WITH_INVOICE: 'START_FINALIZE_APPOINTMENT_WITH_INVOICE',
  GET_FINALIZED_APPOINTMENT_REQUEST: 'GET_FINALIZED_APPOINTMENT_REQUEST',
  GET_FINALIZED_APPOINTMENT_SUCCESS: 'GET_FINALIZED_APPOINTMENT_SUCCESS',
  GET_FINALIZED_APPOINTMENT_ERROR: 'GET_FINALIZED_APPOINTMENT_ERROR',
  DOWNLOADING_FINALIZED_APPOINTMENT: 'DOWNLOADING_FINALIZED_APPOINTMENT',
  CLEAR_FINALIZED_APPOINTMENT_SUCCESS: 'CLEAR_FINALIZED_APPOINTMENT_SUCCESS',
  SET_MESSAGES_CENTER_NUMBER: 'SET_MESSAGES_CENTER_NUMBER',
  GET_NEW_ENTRANCE_MODAL_FULL_WIDTH_REQUEST: 'GET_NEW_ENTRANCE_MODAL_FULL_WIDTH_REQUEST',
  GET_NEW_ENTRANCE_MODAL_FULL_WIDTH_SUCCESS: 'GET_NEW_ENTRANCE_MODAL_FULL_WIDTH_SUCCESS',
  SET_NEW_ENTRANCE_MODAL_FULL_WIDTH_REQUEST: 'SET_NEW_ENTRANCE_MODAL_FULL_WIDTH_REQUEST',
  SET_NEW_ENTRANCE_MODAL_FULL_WIDTH_SUCCESS: 'SET_NEW_ENTRANCE_MODAL_FULL_WIDTH_SUCCESS',
  SET_MODAL_CONFIRMATION_MESSAGE: 'SET_MODAL_CONFIRMATION_MESSAGE',
  CONFIRM_MANUAL_CONFIRMATION_REQUEST_SUCCESS: 'CONFIRM_MANUAL_CONFIRMATION_REQUEST_SUCCESS',
  CLEAR_MANUAL_CONFIRMATION_DATA: 'CLEAR_MANUAL_CONFIRMATION_DATA',
  CHANGE_CALENDAR_COLOR: 'CHANGE_CALENDAR_COLOR',
  SET_SELECTED_LABEL_REQUEST: 'SET_SELECTED_LABEL_REQUEST',
  SET_SELECTED_LABEL_SUCCESS: 'SET_SELECTED_LABEL_SUCCESS',
  SET_SELECTED_LABEL_ERROR: 'SET_SELECTED_LABEL_ERROR',
  START_SETTING_SELECTED_LABEL: 'START_SETTING_SELECTED_LABEL',
  SAVE_AGENDA_LABEL_REQUEST: 'SAVE_AGENDA_LABEL_REQUEST',
  SAVING_AGENDA_LABEL: 'SAVING_AGENDA_LABEL',
  SET_NEW_BATCHED_LISTENER: 'SET_NEW_BATCHED_LISTENER',
  SET_NEW_PERSISTED_LISTENER: 'SET_NEW_PERSISTED_LISTENER',
  CHANGE_BATCHED_START_END_DATES: 'CHANGE_BATCHED_START_END_DATES',
  SET_FULL_BATCHED_APPOINTMENTS_ARR: 'SET_FULL_BATCHED_APPOINTMENTS_ARR',
  RESET_BATCHED_APPOINTMENTS: 'RESET_BATCHED_APPOINTMENTS',
  SET_BATCHED_APPOINTMENTS: 'SET_BATCHED_APPOINTMENTS',
  CREATE_BATCH_APPOINTMENT_REQUEST: 'CREATE_BATCH_APPOINTMENT_REQUEST',
  CREATE_BATCH_APPOINTMENT_SUCCESS: 'CREATE_BATCH_APPOINTMENT_SUCCESS',
  CREATE_BATCH_APPOINTMENT_ERROR: 'CREATE_BATCH_APPOINTMENT_ERROR',
  SET_PERSISTED_BATCHED_APPOINTMENTS: 'SET_PERSISTED_BATCHED_APPOINTMENTS',
  PERSIST_BATCHED_APPOINTMENT_REQUEST: 'PERSIST_BATCHED_APPOINTMENT_REQUEST',
  PERSIST_BATCHED_APPOINTMENT_SUCCESS: 'PERSIST_BATCHED_APPOINTMENT_SUCCESS',
  PERSIST_BATCHED_APPOINTMENT_ERROR: 'PERSIST_BATCHED_APPOINTMENT_ERROR',
  START_PERSISTING_BATCHED_APPOINTMENT: 'START_PERSISTING_BATCHED_APPOINTMENT',
  UPDATE_BATCH_APPOINTMENT_REQUEST: 'UPDATE_BATCH_APPOINTMENT_REQUEST',
  UPDATE_BATCH_APPOINTMENT_SUCCESS: 'UPDATE_BATCH_APPOINTMENT_SUCCESS',
  UPDATE_BATCH_APPOINTMENT_ERROR: 'UPDATE_BATCH_APPOINTMENT_ERROR',
  CANCEL_BATCH_APPOINTMENT_REQUEST: 'CANCEL_BATCH_APPOINTMENT_REQUEST',
  CANCEL_BATCH_APPOINTMENT_SUCCESS: 'CANCEL_BATCH_APPOINTMENT_SUCCESS',
  CANCEL_BATCH_APPOINTMENT_ERROR: 'CANCEL_BATCH_APPOINTMENT_ERROR',
  UPDATE_BATCH_BLOCKED_REQUEST: 'UPDATE_BATCH_BLOCKED_REQUEST',
  UPDATE_BATCH_BLOCKED_SUCCESS: 'UPDATE_BATCH_BLOCKED_SUCCESS',
  UPDATE_BATCH_BLOCKED_RERROR: 'UPDATE_BATCH_BLOCKED_RERROR',
  PERSIST_BATCHED_BLOCKED_REQUEST: 'PERSIST_BATCHED_BLOCKED_REQUEST',
  PERSIST_BATCHED_BLOCKED_SUCCESS: 'PERSIST_BATCHED_BLOCKED_SUCCESS',
  PERSIST_BATCHED_BLOCKED_ERROR: 'PERSIST_BATCHED_BLOCKED_ERROR',
  CONTINUE_EDIT_BATCHED_APPOINTMENT: 'CONTINUE_EDIT_BATCHED_APPOINTMENT',
  UNBLOCK_BATCH_EVENT_REQUEST: 'UNBLOCK_BATCH_EVENT_REQUEST',
  EDIT_ALERT_FOR_APPOINTMENT: 'EDIT_ALERT_FOR_APPOINTMENT',
  getAgendas: () => ({
    type: actions.FETCH_AGENDAS_FROM_FIRESTORE_REQUEST,
  }),
  requestsFetch: (addressUid) => ({
    type: actions.REQUESTS_FETCH,
    payload: addressUid,
  }),
  selectAgenda: (id) => ({
    type: actions.SELECT_AGENDA,
    payload: id,
  }),
  createNewAgendaId: () => ({
    type: actions.CREATE_NEW_AGENDA_ID_REQUEST,
  }),
  saveAgendaSettings: (agendaKey = null, agendaObj) => ({
    type: actions.SAVE_AGENDA_SETTINGS_REQUEST,
    payload: {
      agendaKey,
      agendaObj,
    },
  }),
  deleteAgenda: (agendaId) => ({
    type: actions.DELETE_AGENDA_REQUEST,
    payload: agendaId,
  }),
  confirmRequest: (requestId, agendaId) => ({
    type: actions.CONFIRM_REQUEST,
    payload: {
      requestId,
      agendaId,
    },
  }),
  rejectRequest: (appointment, agendaId) => ({
    type: actions.REJECT_REQUEST,
    payload: {
      appointment,
      agendaId,
    },
  }),
  editAppointmentTime: (selectedData, start, end, blockAll = false) => ({
    type: actions.EDIT_APPOINTMENT_TIME_REQUEST,
    payload: {
      selectedData,
      start,
      end,
      blockAll,
    },
  }),
  editAppointmentNotes: (selectedData, text) => ({
    type: actions.EDIT_APPOINTMENT_NOTES_REQUEST,
    payload: {
      selectedData,
      text,
    },
  }),
  setManualConfirmationMessageSent: (selectedData, manualConfirmationValue) => ({
    type: actions.SET_MANUAL_CONFIRMATION_MESSAGE_SENT_REQUEST,
    payload: {
      selectedData,
      manualConfirmationValue,
    },
  }),
  editAlertForAppointment: (selectedData, appointmentAlerts) => ({
    type: actions.EDIT_ALERT_FOR_APPOINTMENT,
    payload: {
      selectedData,
      appointmentAlerts,
    },
  }),
  patientArrivedTime: (selectedData) => ({
    type: actions.ARRIVED_TIME_REQUEST,
    payload: selectedData,
  }),
  patientMissedAppointment: (selectedData) => ({
    type: actions.PATIENT_MISSED_APPOINTMENT_REQUEST,
    payload: selectedData,
  }),
  patientAttendanceStarted: (selectedData) => ({
    type: actions.PATIENT_BEING_ATTENDING_REQUEST,
    payload: selectedData,
  }),
  messageWasRead: (selectedData) => ({
    type: actions.MESSAGE_READ_REQUEST,
    payload: selectedData,
  }),
  clearDidReadMessage: () => ({
    type: actions.CLEAR_DID_READ_MESSAGE,
  }),
  endAppointment: (selectedData) => ({
    type: actions.END_APPOINTMENT_REQUEST,
    payload: selectedData,
  }),
  cancelAppointment: (selectedData) => ({
    type: actions.CANCEL_APPOINTMENT_REQUEST,
    payload: selectedData,
  }),
  patientConfirmedAppointment: (selectedData) => ({
    type: actions.PATIENT_CONFIRMED_APPOINTMENT_REQUEST,
    payload: selectedData,
  }),
  finalizeAppointment: (selectedData) => ({
    type: actions.FINALIZE_APPOINTMENT_REQUEST,
    payload: selectedData,
  }),
  setSurgery: (selectedData) => ({
    type: actions.SET_SURGERY_REQUEST,
    payload: selectedData,
  }),
  manuallySetNewAppointment: (
    professional,
    patient,
    time,
    duration,
    notes,
    proceduresArr,
    sendMessages,
    isVideoCall,
    videoCallMode,
    patientWhatsApp = null,
    addPatientToWaitList = false,
    patientWaitListObj = {},
  ) => ({
    type: actions.MANUALLY_SET_NEW_APPOINTMENT_REQUEST,
    payload: {
      professional,
      user: patient,
      time,
      duration,
      notes,
      proceduresArr,
      sendMessages,
      isVideoCall,
      videoCallMode,
      patientWhatsApp,
      addPatientToWaitList,
      patientWaitListObj,
    },
  }),
  createBatchAppointment: (
    professional,
    patient,
    time,
    duration,
    notes,
    proceduresArr,
    sendMessages,
    isVideoCall,
    videoCallMode,
    patientWhatsApp = null,
    // addPatientToWaitList = false,
    // patientWaitListObj = {},
    selectedRrule = {},
  ) => ({
    type: actions.CREATE_BATCH_APPOINTMENT_REQUEST,
    payload: {
      professional,
      user: patient,
      time,
      duration,
      notes,
      proceduresArr,
      sendMessages,
      isVideoCall,
      videoCallMode,
      patientWhatsApp,
      // addPatientToWaitList,
      // patientWaitListObj,
      selectedRrule,
    },
  }),
  updateBatchAppointment: (
    selectedData,
    professional,
    time,
    duration,
    notes,
    proceduresArr,
    sendMessages,
    isVideoCall,
    videoCallMode,
    videoCallCreator,
    wasVideoCall,
    videoCallLink,
    videoCallId,
    selectedRrule = {},
    saveBatchedOption,
  ) => ({
    type: actions.UPDATE_BATCH_APPOINTMENT_REQUEST,
    payload: {
      selectedData,
      params: {
        professional,
        time,
        duration,
        notes,
        proceduresArr,
        sendMessages,
        isVideoCall,
        videoCallMode,
        selectedRrule,
      },
      videoCallCreator,
      wasVideoCall,
      videoCallLink,
      videoCallId,
      saveBatchedOption,
    },
  }),
  updateBatchBlocked: (
    selectedData,
    time,
    duration,
    notes,
    selectedRrule = {},
    saveBatchedOption,
  ) => ({
    type: actions.UPDATE_BATCH_BLOCKED_REQUEST,
    payload: {
      selectedData,
      saveBatchedOption,
      params: {
        time,
        duration,
        notes,
        selectedRrule,
      },
    },
  }),
  editWholeAppointment: (
    selectedData,
    professional,
    time,
    duration,
    notes = '',
    proceduresArr,
    resendMessage,
    isVideoCall,
    videoCallMode,
    videoCallCreator,
    // changeVideoCallCreator,
    wasVideoCall,
    videoCallLink,
    videoCallId,
    isScheduleEqual,
    isDurationEqual,
    addPatientToWaitList = false,
    patientWaitListObj = {},
  ) => ({
    type: actions.EDIT_WHOLE_APPOINTMENT_REQUEST,
    payload: {
      selectedData,
      params: {
        professional,
        time,
        duration,
        notes,
        proceduresArr,
        resendMessage,
        isVideoCall,
        videoCallMode,
        videoCallCreator,
        // changeVideoCallCreator,
        wasVideoCall,
        videoCallLink,
        videoCallId,
        isScheduleEqual,
        isDurationEqual,
        addPatientToWaitList,
        patientWaitListObj,
      },
    },
  }),
  cancelBatchAppointment: (selectedData, saveBatchedOption) => ({
    type: actions.CANCEL_BATCH_APPOINTMENT_REQUEST,
    payload: {
      selectedData,
      saveBatchedOption,
    },
  }),
  blockDays: (days) => ({
    type: actions.BLOCK_DAYS_REQUEST,
    payload: days,
  }),
  unblockDay: (event) => ({
    type: actions.UNBLOCK_DAY_REQUEST,
    payload: event,
  }),
  unblockMultipleDays: (events) => ({
    type: actions.UNBLOCK_MULTIPLE_DAYS_REQUEST,
    payload: events,
  }),
  unblockBatchEvent: (event, saveBatchedOption) => ({
    type: actions.UNBLOCK_BATCH_EVENT_REQUEST,
    payload: {
      event,
      saveBatchedOption,
    },
  }),
  blockTime: (time, duration, notes, selectedRrule = {}) => ({
    type: actions.BLOCK_TIME_REQUEST,
    payload: {
      time,
      duration,
      notes,
      selectedRrule,
    },
  }),
  clearAppointmentStatus: (selectedData) => ({
    type: actions.CLEAR_APPOINTMENT_STATUS_REQUEST,
    payload: selectedData,
  }),
  updateAppointmentValue: (selectedData, value) => ({
    type: actions.UPDATE_APPOINTMENT_VALUE_REQUEST,
    payload: {
      selectedData,
      value,
    },
  }),
  notifyNewAppointmentTime: (selectedData, start, end, blockAll = false) => ({
    type: actions.NOTIFY_NEW_APPOINTMENT_TIME_REQUEST,
    payload: {
      selectedData,
      start,
      end,
      blockAll,
    },
  }),
  notifyNewBacthedAppointmentTime: (selectedData) => ({
    type: actions.NOTIFY_NEW_BATCHED_APPOINTMENT_TIME_REQUEST,
    payload: {
      ...selectedData,
    },
  }),
  // changeSavedAllChanges: (id, obj) => ({
  //   type: actions.CHANGE_SAVED_ALL_CHANGES,
  //   payload: {
  //     id,
  //     obj,
  //   },
  // }),
  getPatientAppointment: (id) => ({
    type: actions.GET_PATIENT_APPOINTMENTS_REQUEST,
    payload: id,
  }),
  resetPatientAppointments: () => ({
    type: actions.RESET_PATIENT_APPOINTMENTS,
  }),
  changeAgendaListenerParam: (start, end) => ({
    type: actions.CHANGE_LISTENER_PARAM,
    payload: {
      start,
      end,
    },
  }),
  changePastAgendaListenerParam: (start, end) => ({
    type: actions.CHANGE_PAST_LISTENER_PARAM,
    payload: {
      start,
      end,
    },
  }),
  finalizeAppointmentWithInvoice: (selectedData, invoice = false, values = {}) => ({
    type: actions.FINALIZE_APPOINTMENT_WITH_INVOICE_REQUEST,
    payload: {
      selectedData,
      invoice,
      values,
    },
  }),
  getFinalizedAppointment: (id) => ({
    type: actions.GET_FINALIZED_APPOINTMENT_REQUEST,
    payload: id,
  }),
  clearFinalizedAppointment: () => ({
    type: actions.CLEAR_FINALIZED_APPOINTMENT_SUCCESS,
  }),
  setMessageCenter: (total) => ({
    type: actions.SET_MESSAGES_CENTER_NUMBER,
    payload: total,
  }),
  getFullWidthNewEntrance: () => ({
    type: actions.GET_NEW_ENTRANCE_MODAL_FULL_WIDTH_REQUEST,
  }),
  setFullWidthNewEntrance: (value) => ({
    type: actions.SET_NEW_ENTRANCE_MODAL_FULL_WIDTH_REQUEST,
    payload: value,
  }),
  setManualConfirmationMessageModalVisible: (value) => ({
    type: actions.SET_MODAL_CONFIRMATION_MESSAGE,
    payload: value,
  }),
  clearOnlineConfirmationData: () => ({
    type: actions.CLEAR_MANUAL_CONFIRMATION_DATA,
  }),
  changeCalendarColor: (color) => ({
    type: actions.CHANGE_CALENDAR_COLOR,
    payload: color,
  }),
  setSelectedLabel: (selectedData, key) => ({
    type: actions.SET_SELECTED_LABEL_REQUEST,
    payload: {
      selectedData,
      key,
    },
  }),
  saveAgendaLabel: (item, key) => ({
    type: actions.SAVE_AGENDA_LABEL_REQUEST,
    payload: {
      item,
      key,
    },
  }),
  changeBatchedStartEndDates: (start, end) => ({
    type: actions.CHANGE_BATCHED_START_END_DATES,
    payload: {
      start,
      end,
    },
  }),
  persistBatchedAppointment: (selectedData) => ({
    type: actions.PERSIST_BATCHED_APPOINTMENT_REQUEST,
    payload: {
      selectedData,
      // oldSelectedData,
    },
  }),
  persistBatchedBlocked: (selectedData) => ({
    type: actions.PERSIST_BATCHED_BLOCKED_REQUEST,
    payload: {
      selectedData,
    },
  }),
};

export default actions;
