const actions = {
  INSERT_PATIENT_ALERT: 'INSERT_PATIENT_ALERT',
  INSERT_PATIENT_ALERT_WAITING: 'INSERT_PATIENT_ALERT_WAITING',
  INSERT_PATIENT_ALERT_SUCCESS: 'INSERT_PATIENT_ALERT_SUCCESS',
  INSERT_PATIENT_ALERT_ERROR: 'INSERT_PATIENT_ALERT_ERROR',
  INSERT_MULTIPLE_PATIENT_ALERT: 'INSERT_MULTIPLE_PATIENT_ALERT',
  FETCH_PATIENT_ALERT: 'FETCH_PATIENT_ALERT',
  FETCH_PATIENT_ALERT_WAITING: 'FETCH_PATIENT_ALERT_WAITING',
  FETCH_PATIENT_ALERT_SUCCESS: 'FETCH_PATIENT_ALERT_SUCCESS',
  FETCH_PATIENT_ALERT_FOR_WEEK_SUCCESS: 'FETCH_PATIENT_ALERT_FOR_WEEK_SUCCESS',
  FETCH_PATIENT_ALERT_ERROR: 'FETCH_PATIENT_ALERT_ERROR',
  FETCH_PATIENT_ALERT_CUSTOM_DATE: 'FETCH_PATIENT_ALERT_CUSTOM_DATE',
  FETCH_PATIENT_ALERT_CUSTOM_DATE_WAITING: 'FETCH_PATIENT_ALERT_CUSTOM_DATE_WAITING',
  FETCH_PATIENT_ALERT_CUSTOM_DATE_SUCCESS: 'FETCH_PATIENT_ALERT_CUSTOM_DATE_SUCCESS',
  FETCH_PATIENT_ALERT_CUSTOM_DATE_ERROR: 'FETCH_PATIENT_ALERT_CUSTOM_DATE_ERROR',
  DELETE_PATIENT_ALERT: 'DELETE_PATIENT_ALERT',
  DELETE_PATIENT_ALERT_SUCCESS: 'DELETE_PATIENT_ALERT_SUCCESS',
  DELETE_PATIENT_ALERT_ERROR: 'DELETE_PATIENT_ALERT_ERROR',
  FETCH_SINGLE_PATIENT_ALERT: 'FETCH_SINGLE_PATIENT_ALERT',
  FETCH_SINGLE_PATIENT_ALERT_WAITING: 'FETCH_SINGLE_PATIENT_ALERT_WAITING',
  FETCH_SINGLE_PATIENT_ALERT_SUCCESS: 'FETCH_SINGLE_PATIENT_ALERT_SUCCESS',
  FETCH_SINGLE_PATIENT_ALERT_ERROR: 'FETCH_SINGLE_PATIENT_ALERT_ERROR',
  UPDATE_PATIENT_ALERT: 'UPDATE_PATIENT_ALERT',
  UPDATE_PATIENT_ALERT_WAITING: 'UPDATE_PATIENT_ALERT_WAITING',
  UPDATE_PATIENT_ALERT_SUCCESS: 'UPDATE_PATIENT_ALERT_SUCCESS',
  CLEAR_PATIENT_ALERT_STATES: 'CLEAR_PATIENT_ALERT_STATES',
  insertPatientAlert: (selectedData) => ({
    type: actions.INSERT_PATIENT_ALERT,
    payload: selectedData,
  }),
  fetchPatientAlerts: () => ({
    type: actions.FETCH_PATIENT_ALERT,
  }),
  fetchPatientAlertsCustomDate: (startDate = null, endDate = null) => ({
    type: actions.FETCH_PATIENT_ALERT,
    payload: {
      startDate,
      endDate,
    },
  }),
  fetchSinglePatientAlertInformation: (id) => ({
    type: actions.FETCH_SINGLE_PATIENT_ALERT,
    payload: id,
  }),
  updateAlert: (alert, updates) => ({
    type: actions.UPDATE_PATIENT_ALERT,
    payload: {
      alert,
      updates,
    },
  }),
  handleDeleteAlert: (id) => ({
    type: actions.DELETE_PATIENT_ALERT,
    payload: id,
  }),
  clearAlertStates: () => ({
    type: actions.CLEAR_PATIENT_ALERT_STATES,
  }),
};
export default actions;
