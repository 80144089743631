import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  confirmed: false,
  rejected: false,
  updating: null,
  alerts: null,
  singlePatientAlert: null,
  startDate: null,
  endDate: null,
  patientId: null,
  insertedSuccess: null,
};

export default function patientAlerts(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.CLEAR_PATIENT_ALERT_STATES:
      return {
        ...initState,
      };
    case actions.FETCH_PATIENT_ALERT_WAITING:
      return {
        ...state,
        updating: true,
        singlePatientAlert: null,
      };
    case actions.FETCH_SINGLE_PATIENT_ALERT_WAITING:
      return {
        ...state,
        updating: true,
        startDate: null,
        endDate: null,
      };
    case actions.UPDATE_PATIENT_ALERT_WAITING:
      return {
        ...state,
        updating: true,
      };
    case actions.INSERT_PATIENT_ALERT_WAITING:
      return {
        ...state,
        insertedSuccess: null,
        updating: true,
      };
    case actions.INSERT_PATIENT_ALERT_SUCCESS:
      return {
        ...state,
        insertedSuccess: true,
        updating: false,
      };
    case actions.INSERT_PATIENT_ALERT_ERROR:
      return {
        ...state,
        insertedSuccess: false,
        updating: false,
      };
    case actions.FETCH_PATIENT_ALERT_FOR_WEEK_SUCCESS:
      return {
        ...state,
        patientId: null,
        updating: false,
        weekAlerts: action.payload,
      };
    case actions.FETCH_PATIENT_ALERT_SUCCESS:
      return {
        ...state,
        alerts: action.payload.alerts,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        patientId: null,
        updating: false,
      };
    case actions.FETCH_SINGLE_PATIENT_ALERT_SUCCESS:
      return {
        ...state,
        singlePatientAlert: {
          ...state.singlePatientAlert,
          [action.payload.id]: action.payload.alertArr,
        },
        patientId: action.payload.id,
        endDate: null,
        updating: false,
      };
    case actions.UPDATE_PATIENT_ALERT_SUCCESS:
      return {
        ...state,
        alerts: action.payload,
        updating: false,
      };
    default:
      return state;
  }
}
