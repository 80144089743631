import { combineReducers } from 'redux';
import Auth from './auth/reducer';
import App from './app/reducer';
import Profile from './profile/reducer';
import Agenda from './agenda/reducer';
import Calendar from './calendar/reducer';
import Contacts from './contacts/reducer';
import Searches from './searches/reducer';
import Profiles from './schedule/reducer';
import PatientAppointments from './patientAppointments/reducer';
import Reports from './report/reducer';
import Procedures from './procedures/reducer';
import Exams from './exams/reducer';
import Pdf from './pdf/reducer';
import NewEntrance from './newEntrance/reducer';
import Records from './records/reducer';
import Prescriptions from './prescriptions/reducer';
import Pin from './pin/reducer';
import Medicines from './medicines/reducer';
import Cid from './cid/reducer';
import Certificates from './certificate/reducer';
import CustomUsers from './customUsers/reducer';
import Chat from './chat/reducer';
import Inventory from './inventory/reducer';
import Financial from './financial/reducer';
import Gapi from './gapi/reducer';
import Messages from './messages/reducer';
import Analytics from './analytics/reducer';
import Memed from './memed/reducer';
import WaitList from './waitList/reducer';
import EntrancePersist from './entrancePersist/reducer';
import Telemed from './telemed/reducer';
import PatientAlerts from './patientAlert/reducer';

export default combineReducers({
  Auth,
  App,
  Profile,
  Agenda,
  Calendar,
  Contacts,
  Searches,
  Profiles,
  PatientAppointments,
  Reports,
  Procedures,
  Exams,
  Pdf,
  NewEntrance,
  Records,
  Prescriptions,
  Pin,
  Medicines,
  Cid,
  Certificates,
  CustomUsers,
  Chat,
  Inventory,
  Financial,
  Gapi,
  Messages,
  Analytics,
  Memed,
  WaitList,
  EntrancePersist,
  Telemed,
  PatientAlerts,
});
