import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  onValue,
  update,
  push,
  get,
  child,
  query as dbQuery,
  orderByChild,
  startAt,
  endAt,
  equalTo,
  increment,
} from 'firebase/database';
import axios from 'axios';
import moment from 'moment-timezone';
import {
  all,
  takeEvery,
  takeLatest,
  put,
  call,
  fork,
  select,
  take,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { notification } from '../../components';
import actions from './actions';
import appActions from '../app/actions';
import agendaActions from '../agenda/actions';
import customUsersActions from '../customUsers/actions';
import inventoryActions from '../inventory/actions';
import contactActions from '../contacts/actions';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getSelectedAgendaFromStore = (state) => state.Agenda.selectedAgenda;

const getAgendasArrFromStore = (state) => state.Agenda.agendasArr;

const getSelectedCostCenterProfileFromStore = (state) => state.Financial.selectedCostCenterProfile;

const getSelectedCostCenterProfileArrFromStore = (state) => state.Financial.selectedCostCenterProfileArr;

const getCostCenterProfilesFromStore = (state) => state.Financial.costCenterProfiles;

const getFinancialContactsFromStore = (state) => state.Financial.financialContacts;

const getFinalizedAppointmentListenerTimeFromStore = (state) => state.Financial.finalizedAppointmentListenerTime;

const getFinalizedAppointmentListenerCustomTimeFromStore = (state) => state.Financial.finalizedAppointmentListenerCustomTime;

const getFinalizedAppointmentListenerTimeRangeFromStore = (state) => state.Financial.finalizedAppointmentListenerTimeRange;

const getManualOpsListenerCustomTimeFromStore = (state) => state.Financial.manualOpsListenerCustomTime;

const getManualOpsListenerTimeFromStore = (state) => state.Financial.manualOpsListenerTime;

const getManualOpsListenerTimeRangeFromStore = (state) => state.Financial.manualOpsListenerTimeRange;

const getFinalizedAppointmentsFromStore = (state) => state.Financial.finalizedAppointmentsArr;

const getManualOpsFromStore = (state) => state.Financial.manualOpsArr;

const getBankStatementListFromStore = (state) => state.Financial.bankStatementList;

const getNextTransactionsListFromStore = (state) => state.Financial.nextTransactionsList;

const getInventoryOpsFromStore = (state) => state.Inventory.inventoryOps;

const getFinancialHistoryFromStore = (state) => state.Contacts.financialHistory;

const getSeectedIdFromStore = (state) => state.Contacts.seectedId;

const getCustomUsersFromStore = (state) => state.CustomUsers.customUsers;

const getCustomUsersInitialFetchFromStore = (state) => state.CustomUsers.initialFetch;

function getFinancialSettingsFromDB(addressUid, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  // return db.ref(`financial/${uid}/${addressUid}/settings`).once('value');
  return new Promise((resolve) => {
    onValue(ref(db, `financial/${uid}/${addressUid}/settings`), resolve, { onlyOnce: true });
  });
}

export function* getFinancialSettingsRequest() {
  yield takeLatest(actions.GET_FINANCIAL_SETTINGS_REQUEST, function* () {
    try {
      // yield put({ type: actions.CHECKING_FINANCIAL_PAYMENTS });
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const settings = yield call(
        getFinancialSettingsFromDB,
        addressUid,
        mainUser,
      );
      const auth = getAuth();
      const { currentUser } = auth;
      const obj = {
        invoiceAutomation: false,
        showFinalizedAppointmentModal: {
          [currentUser.uid]: false,
        },
      };
      if (settings.val()) {
        _.merge(obj, settings.val());
      }
      yield put({
        type: actions.GET_FINANCIAL_SETTINGS_SUCCESS,
        // payload: {
        //   invoiceAutomation: invoiceAutomation.val(),
        //   address: addressUid,
        // },
        payload: {
          financialSettings: obj,
          address: addressUid,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_FINANCIAL_SETTINGS_ERROR,
      });
    }
  });
}

function saveInvoiceAutomationOnDB(value, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  // updates[`/financial/${uid}/${currentAddress}/invoiceAutomation`] = value;
  updates[`/financial/${uid}/${currentAddress}/settings/invoiceAutomation`] = value;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* changeInvoiceAutomationRequest() {
  yield takeLatest(actions.CHANGE_INVOICE_AUTOMATION_REQUEST, function* (action) {
    try {
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        saveInvoiceAutomationOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.GET_FINANCIAL_SETTINGS_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CHANGE_INVOICE_AUTOMATION_ERROR,
      });
    }
  });
}

function saveShowFinalizedAppointmentModalOnDB(value, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/financial/${uid}/${currentAddress}/settings/showFinalizedAppointmentModal/${currentUser.uid}`] = value;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* changeShowFinalizedAppointmentModalRequest() {
  yield takeLatest(actions.CHANGE_SHOW_FINALIZED_APPOINTMENT_MODAL_REQUEST, function* (action) {
    try {
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        saveShowFinalizedAppointmentModalOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.GET_FINANCIAL_SETTINGS_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CHANGE_SHOW_FINALIZED_APPOINTMENT_MODAL_ERROR,
      });
    }
  });
}

function checkFinancialPaymentsInCloud(
  costCenterProfile,
  addressId,
  idToken,
  mainUser,
) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressId,
    costCenterProfile,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/checkFinancialTransactions`,
    bodyParameters,
    config,
  );
}

export function* checkFinancialTransactionsRequest() {
  yield takeLatest([
    actions.CHECK_FINANCIAL_TRANSACTIONS_REQUEST,
    // actions.SELECT_COST_CENTER_PROFILE,
    // actions.SELECT_COST_CENTER_PROFILE_ARR,
  ], function* () {
    try {
      yield put({ type: actions.CHECKING_FINANCIAL_PAYMENTS });
      const addressUid = yield select(getSelectedAddressFromStore);
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const costCenterProfiles = yield select(getCostCenterProfilesFromStore);
      let selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      while (!selectedCostCenterProfileArr[addressUid]) {
        yield take(actions.SELECT_COST_CENTER_PROFILE_ARR);
        selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      }
      const checkFinancialTransactionsArr = yield all(costCenterProfiles[addressUid].map(({ id: costCenter }) => call(
        checkFinancialPaymentsInCloud,
        costCenter,
        addressUid,
        idToken,
        mainUser,
      )));
      let amountEnded = 0;
      for (let i = 0; i < selectedCostCenterProfileArr[addressUid].length; i += 1) {
        const { status } = checkFinancialTransactionsArr[i];
        if (status) {
          if (status === 201) {
            amountEnded += 1;
          }
        }
      }
      if (amountEnded === selectedCostCenterProfileArr[addressUid].length) {
        yield put({
          type: actions.CHECK_FINANCIAL_TRANSACTIONS_SUCCESS,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CHECK_FINANCIAL_TRANSACTIONS_ERROR,
      });
    }
  });
}

function createBalanceListener(costCenterProfile, addressUid, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `financial/${uid}/${addressUid}/balance/${costCenterProfile}`);
    const unsubscribe = onValue(databaseRef, (req) => (
      emit(req.val() || 0)
    ));
    return () => unsubscribe();
  });
  return listener;
}

function getBalanceFromDB(costCenterProfile, addressUid, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `financial/${uid}/${addressUid}/balance/${costCenterProfile}`));
}

export function* getBalanceRequest() {
  yield takeEvery(actions.GET_BALANCE_REQUEST, function* () {
    const addressUid = yield select(getSelectedAddressFromStore);
    const mainUser = yield select(getMainUserFromStore);

    let selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
    while (!selectedCostCenterProfileArr[addressUid]) {
      yield take(actions.SELECT_COST_CENTER_PROFILE_ARR);
      selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
    }
    const balanceListenerArr = yield all(selectedCostCenterProfileArr[addressUid].map((costCenter) => call(
      createBalanceListener,
      costCenter,
      addressUid,
      mainUser,
    )));
    yield all(balanceListenerArr.map((balanceListener, listenerIndex) => takeEvery(balanceListener, function* (balance) {
      yield put({
        type: actions.GET_BALANCE_SUCCESS,
        payload: {
          balance,
          costCenterProfile: selectedCostCenterProfileArr[addressUid][listenerIndex],
          address: addressUid,
        },
      });
    })));

    const actionTriggered = yield take([
      appActions.CANCEL_LISTENERS,
      actions.SELECT_COST_CENTER_PROFILE_ARR,
    ]);

    balanceListenerArr.forEach((el) => {
      el.close();
    });

    if (actionTriggered?.type === 'SELECT_COST_CENTER_PROFILE_ARR') {
      // Resetting listener to filter new 'costProfile'.
      yield put({
        type: actions.GET_BALANCE_REQUEST,
      });
    } else {
      // It is the general "cancel all listeners".
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_BALANCE }),
      ]);
    }
  });
}

function createCashBalanceListener(costCenterProfile, addressUid, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `financial/${uid}/${addressUid}/cashBalance/${costCenterProfile}`);
    const unsubscribe = onValue(databaseRef, (req) => (
      emit(req.val() || 0)
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getCashBalanceRequest() {
  yield takeEvery(actions.GET_CASH_BALANCE_REQUEST, function* () {
    const addressUid = yield select(getSelectedAddressFromStore);
    const mainUser = yield select(getMainUserFromStore);

    let selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
    while (!selectedCostCenterProfileArr[addressUid]) {
      yield take(actions.SELECT_COST_CENTER_PROFILE_ARR);
      selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
    }
    const balanceListenerArr = yield all(selectedCostCenterProfileArr[addressUid].map((costCenter) => call(
      createCashBalanceListener,
      costCenter,
      addressUid,
      mainUser,
    )));
    yield all(balanceListenerArr.map((balanceListener, listenerIndex) => takeEvery(balanceListener, function* (balance) {
      yield put({
        type: actions.GET_CASH_BALANCE_SUCCESS,
        payload: {
          balance,
          costCenterProfile: selectedCostCenterProfileArr[addressUid][listenerIndex],
          address: addressUid,
        },
      });
    })));
    const actionTriggered = yield take([
      appActions.CANCEL_LISTENERS,
      actions.SELECT_COST_CENTER_PROFILE_ARR,
    ]);

    balanceListenerArr.forEach((el) => {
      el.close();
    });

    if (actionTriggered?.type === 'SELECT_COST_CENTER_PROFILE_ARR') {
      // Resetting listener to filter new 'costProfile'.
      yield put({
        type: actions.GET_CASH_BALANCE_REQUEST,
      });
    } else {
      // It is the general "cancel all listeners".
      yield all([
        put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
        put({ type: actions.RESET_CASH_BALANCE }),
      ]);
    }
  });
}

function getInventoryOpsFromDB(currentAddress, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const databaseRef = ref(db, `inventory/${uid}/${currentAddress}/ops`);
  return get(dbQuery(
    databaseRef,
    orderByChild('type'),
    equalTo('deposit'),
  ));
  // return db.ref(`inventory/${uid}/${currentAddress}/ops`)
  //   .orderByChild('type')
  //   .equalTo('deposit')
  //   // .limitToFirst(20)
  //   // .startAt(startAt)
  //   // .endAt(`${endAt}\uf8ff`)
  //   .once('value');
}

function getFinalizedAppointmentsFromDB(addressId, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const databaseRef = ref(db, `finalizedAppointments/${uid}/${addressId}`);
  return get(dbQuery(
    databaseRef,
    orderByChild('invoiceStatus'),
    equalTo('confirmed'),
  ));
  // return db.ref(`finalizedAppointments/${uid}/${addressId}`)
  //   .orderByChild('invoiceStatus')
  //   .equalTo('confirmed')
  //   // .limitToFirst(20)
  //   // .startAt(startAt)
  //   // .endAt(`${endAt}\uf8ff`)
  //   .once('value');
}

// Not being used anymore.
// It was used in 'TransactionsShortList' component, but this component is not being imported anywhere.
export function* getOperationsListRequest() {
  yield takeLatest([
    actions.GET_OPERATIONS_LIST_REQUEST,
    // actions.GET_BALANCE_SUCCESS,
  ], function* () {
    let currentAddress = yield select(getSelectedAddressFromStore);
    let agendaId = yield select(getSelectedAgendaFromStore);
    try {
      yield put({ type: actions.OPERATIONS_LIST_WAITING });
      yield call(sleep, 500);
      if (!currentAddress) {
        yield take(appActions.SELECT_ADDRESS);
        currentAddress = yield select(getSelectedAddressFromStore);
      }
      if (!agendaId) {
        yield take(agendaActions.SELECT_AGENDA);
        agendaId = yield select(getSelectedAgendaFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const selectedCostCenterProfile = yield select(getSelectedCostCenterProfileFromStore);
      const [inventoryList, finalizedAppointments] = yield all([
        call(getInventoryOpsFromDB, currentAddress, mainUser),
        call(getFinalizedAppointmentsFromDB, currentAddress, mainUser),
      ]);
      let inventoryOps = [];
      if (inventoryList.val()) {
        inventoryOps = _.map(inventoryList.val(), (val, opsId) => (
          {
            ...val,
            id: opsId,
            operationType: 'expense',
            inventory: true,
          }
        ));
      }
      let finalizedAppointmentsArr = [];
      if (finalizedAppointments.val()) {
        finalizedAppointmentsArr = _.map(finalizedAppointments.val(), (val, appointmentId) => (
          {
            ...val,
            id: appointmentId,
            operationType: 'income',
            appointment: true,
          }
        ));
      }
      let finalOperationList = [];
      if (selectedCostCenterProfile?.[currentAddress]) {
        finalOperationList = [...inventoryOps, ...finalizedAppointmentsArr]
          .filter((el) => el.costCenterProfile === selectedCostCenterProfile[currentAddress]);
      }
      yield put({
        type: actions.GET_OPERATIONS_LIST_SUCCESS,
        payload: {
          operationsList: _.orderBy(
            finalOperationList,
            [(el) => moment(el.timestamp).format()],
            'desc',
          ),
          address: currentAddress,
          // finalizedAppointmentsArr,
        },
      });
      // if (inventory.val()) {
      //   let inventoryOps = [];
      //   if (inventory.val()) {
      //     inventoryOps = _.map(inventory.val(), (val, opsId) => (
      //       { ...val, id: opsId }
      //     ));
      //   }
      //   yield put({
      //     type: actions.INVENTORY_OPS_FETCH_SUCCESS,
      //     payload: {
      //       inventoryOps,
      //       address: currentAddress,
      //     },
      //   });
      // } else {
      //   yield put({
      //     type: actions.INVENTORY_OPS_FETCH_SUCCESS,
      //     payload: {
      //       inventoryOps: [],
      //       address: currentAddress,
      //     },
      //   });
      // }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_OPERATIONS_LIST_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

function getBankStatementFromCloud(
  startDate,
  endDate,
  costCenterProfile,
  addressId,
  idToken,
  mainUser,
) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressId,
    startDate,
    endDate,
    costCenterProfile,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/getBankStatement`,
    bodyParameters,
    config,
  );
}

export function* getBankStatementRequest() {
  yield takeLatest(actions.GET_BANK_STATEMENT_REQUEST, function* (action) {
    try {
      yield put({ type: actions.FETCHING_BANK_STATEMENT });
      const addressUid = yield select(getSelectedAddressFromStore);
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const { startDate, endDate } = action.payload;

      let selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      while (!selectedCostCenterProfileArr[addressUid]) {
        yield take(actions.SELECT_COST_CENTER_PROFILE_ARR);
        selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      }
      if (startDate && endDate) {
        const bankStatementArr = yield all(selectedCostCenterProfileArr[addressUid].map((costCenter) => call(
          getBankStatementFromCloud,
          startDate,
          endDate,
          costCenter,
          addressUid,
          idToken,
          mainUser,
        )));
        const bankStatementList = [];
        for (let i = 0; i < selectedCostCenterProfileArr[addressUid].length; i += 1) {
          const { data } = bankStatementArr[i];
          if (data) {
            bankStatementList.push(...data);
          }
        }
        yield put({
          type: actions.GET_BANK_STATEMENT_SUCCESS,
          payload: {
            bankStatementList: _.orderBy(bankStatementList, (e) => (e?.transactionInfo?.date ? e.transactionInfo.date : ''), ['asc']),
            // costCenterProfile: selectedCostCenterProfileArr[addressUid][i],
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_BANK_STATEMENT_ERROR,
      });
    }
  });
}

function createDefaultCostCenterProfileOnDB(currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/financial/${uid}/${currentAddress}/costCenter/profiles`)).key;
  updates[`/financial/${uid}/${currentAddress}/costCenter/profiles/${pushKey}`] = {
    default: true,
    name: 'Padrão',
  };
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function createProfessionalCostCenterProfileOnDB(professionals, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  professionals.forEach((el) => {
    const formattedFirstName = capitalizeFirstLetter(el.firstName.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, ''));
    const formattedLastName = el.lastName ? capitalizeFirstLetter(el.lastName.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '')) : '';
    updates[`/financial/${uid}/${currentAddress}/costCenter/profiles/${el.id}`] = {
      name: `${formattedFirstName} ${formattedLastName}`,
      timestamp: moment().tz('America/Sao_Paulo').format(),
      desc: `Centro de custo criado automaticamente para computar as transações feitas pelo profissional ${formattedFirstName} ${formattedLastName}.`,
      customHealthProfessional: true,
    };
  });
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function getCostCenterFromDB(currentAddress, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `financial/${uid}/${currentAddress}/costCenter/profiles`));
}

function* fetchCostCenterBalanceArray(arr, addressId, mainUser) {
  return yield all(arr.map((s) => call(getBalanceFromDB, s.id, addressId, mainUser)));
}

export function* getCostCenterProfilesRequest() {
  yield takeLatest(actions.GET_COST_CENTER_REQUEST, function* () {
    let currentAddress = yield select(getSelectedAddressFromStore);
    try {
      if (!currentAddress) {
        yield take(appActions.SELECT_ADDRESS);
        currentAddress = yield select(getSelectedAddressFromStore);
      }
      let customUsers = yield select(getCustomUsersFromStore);
      let customUsersInitialFetch = yield select(getCustomUsersInitialFetchFromStore);
      if (!customUsersInitialFetch) {
        yield take(customUsersActions.CUSTOM_USERS_FETCH_SUCCESS);
        customUsersInitialFetch = true;
        customUsers = yield select(getCustomUsersFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const costCenter = yield call(getCostCenterFromDB, currentAddress, mainUser);
      if (costCenter.val()) {
        const costCenterProfiles = _.map(costCenter.val(), (val, id) => (
          { ...val, id }
        ));
        const professionalCustomUsers = customUsers.filter((el) => el.customHealthProfessional);
        if (professionalCustomUsers.length > 0) {
          const missingProfessionalCostCenter = professionalCustomUsers.filter((user) => {
            const foundCostCenter = costCenterProfiles.find((el) => el.id === user.id);
            if (!foundCostCenter) {
              return true;
            }
            return false;
          });
          if (missingProfessionalCostCenter.length > 0) {
            yield call(createProfessionalCostCenterProfileOnDB, missingProfessionalCostCenter, currentAddress, mainUser);
            yield put({
              type: actions.GET_COST_CENTER_REQUEST,
            });
          }
        }
        const balanceResponses = yield call(fetchCostCenterBalanceArray, costCenterProfiles, currentAddress, mainUser);
        // const balanceResponses = yield costCenterProfiles.map((el) => call(getBalanceFromDB, el.id, currentAddress, mainUser));
        balanceResponses.forEach((item, index) => {
          if (item.val()) {
            costCenterProfiles[index].balance = item.val();
          } else {
            costCenterProfiles[index].balance = 0;
          }
        });
        const defaultCostCenterProfile = costCenterProfiles.find((el) => el.default);
        yield all([
          put({
            type: actions.GET_COST_CENTER_SUCCESS,
            payload: {
              costCenterProfiles,
              address: currentAddress,
            },
          }),
          put({
            type: actions.SELECT_COST_CENTER_PROFILE,
            payload: {
              selectedCostCenterProfile: defaultCostCenterProfile.id,
              address: currentAddress,
            },
          }),
          put({
            type: actions.SELECT_COST_CENTER_PROFILE_ARR,
            payload: {
              selectedCostCenterProfileArr: [defaultCostCenterProfile.id],
              address: currentAddress,
            },
          }),
        ]);
        // yield put({
        //   type: actions.GET_COST_CENTER_SUCCESS,
        //   payload: {
        //     costCenterProfiles,
        //     address: currentAddress,
        //   },
        // });
      } else {
        // No cost center yet. Need to create one.
        yield call(
          createDefaultCostCenterProfileOnDB,
          currentAddress,
          mainUser,
        );
        yield put({
          type: actions.GET_COST_CENTER_REQUEST,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_COST_CENTER_ERROR,
        payload: {
          address: currentAddress,
        },
      });
    }
  });
}

export function* costCenterSelected() {
  yield takeLatest(actions.SELECT_COST_CENTER_PROFILE, function* () {
    yield put({
      type: actions.CHECK_FINANCIAL_TRANSACTIONS_REQUEST,
    });
  });
}

function createCostCenterProfileOnDB(values, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/financial/${uid}/${currentAddress}/costCenter/profiles`)).key;
  updates[`/financial/${uid}/${currentAddress}/costCenter/profiles/${pushKey}`] = { ...values };
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* createCostCenterProfileRequest() {
  yield takeLatest(actions.CREATE_COST_CENTER_PROFILE_REQUEST, function* (action) {
    try {
      yield put({ type: actions.COST_CENTER_FETCH_OR_UPDATING_WAITING });
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        createCostCenterProfileOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.GET_COST_CENTER_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_COST_CENTER_PROFILE_ERROR,
      });
    }
  });
}

function updateCostCenterProfileOnDB(values, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/financial/${uid}/${currentAddress}/costCenter/profiles/${values.id}/name`] = values.name;
  updates[`/financial/${uid}/${currentAddress}/costCenter/profiles/${values.id}/desc`] = values.desc;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updateCostCenterProfileRequest() {
  yield takeLatest(actions.UPDATE_COST_CENTER_PROFILE_REQUEST, function* (action) {
    try {
      yield put({ type: actions.COST_CENTER_FETCH_OR_UPDATING_WAITING });
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        updateCostCenterProfileOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.GET_COST_CENTER_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_COST_CENTER_PROFILE_ERROR,
      });
    }
  });
}

function removeCostCenterProfileOnDB(id, currentAddress, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/financial/${uid}/${currentAddress}/costCenter/profiles/${id}/disabled`] = true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeCostCenterProfileRequest() {
  yield takeLatest(actions.REMOVE_COST_CENTER_PROFILE_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.REMOVING_COST_CENTER_PROFILE,
        payload: action.payload,
      });
      yield call(sleep, 500);
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        removeCostCenterProfileOnDB,
        action.payload,
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.GET_COST_CENTER_REQUEST,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REMOVE_COST_CENTER_PROFILE_ERROR,
      });
    }
  });
}

function createFinalizeAppointmentOnDB(
  values,
  // costCenterProfile,
  addressUid,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/finalizedAppointments/${uid}/${addressUid}`)).key;
  updates[`/finalizedAppointments/${uid}/${addressUid}/${pushKey}`] = {
    ...values,
    invoiceStatus: 'confirmed',
    manuallyCreated: true,
  };
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function createFinancialContactOnDB(name, addressUid, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/financial/${uid}/${addressUid}/contacts`)).key;
  updates[`/financial/${uid}/${addressUid}/contacts/${pushKey}`] = {
    name,
    timestamp: moment().tz('America/Sao_Paulo').format(),
  };
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function createManualIncomeOnDB(
  values,
  // costCenterProfile,
  addressUid,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/financial/${uid}/${addressUid}/manualOps`)).key;
  updates[`/financial/${uid}/${addressUid}/manualOps/${pushKey}`] = {
    ...values,
    invoiceStatus: 'confirmed',
    // costCenterProfile,
    type: 'income',
  };
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* createManualIncomeRequest() {
  yield takeLatest(actions.CREATE_MANUAL_INCOME_REQUEST, function* (action) {
    try {
      yield put({ type: actions.CREATING_MANUAL_OPS });
      yield call(sleep, 500);
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      // const selectedCostCenterProfile = yield select(getSelectedCostCenterProfileFromStore);
      if (action.payload.entranceMode === 'appointment') {
        yield call(
          createFinalizeAppointmentOnDB,
          action.payload,
          // selectedCostCenterProfile[currentAddress],
          currentAddress,
          mainUser,
        );
      } else if (action.payload.entranceMode === 'default') {
        const payloadObject = {
          ...action.payload,
        };
        if (action.payload.selectedFinancialContact) {
          let contactsList = yield select(getFinancialContactsFromStore);
          let foundContact = contactsList.find((el) => (
            el.id === action.payload.selectedFinancialContact || el.name === action.payload.selectedFinancialContact
          ));
          if (!foundContact) {
            // It is a new payer.
            yield call(
              createFinancialContactOnDB,
              action.payload.selectedFinancialContact,
              currentAddress,
              mainUser,
            );
            yield put({
              type: actions.GET_FINANCIAL_CONTACTS_REQUEST,
            });
            yield take(actions.GET_FINANCIAL_CONTACTS_SUCCESS);
            contactsList = yield select(getFinancialContactsFromStore);
            foundContact = contactsList.find((el) => (
              el.id === action.payload.selectedFinancialContact || el.name === action.payload.selectedFinancialContact
            ));
          }
          payloadObject.selectedFinancialContact = foundContact.id;
        }
        yield call(
          createManualIncomeOnDB,
          payloadObject,
          // selectedCostCenterProfile[currentAddress],
          currentAddress,
          mainUser,
        );
      }
      yield put({
        type: actions.CREATE_MANUAL_INCOME_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_MANUAL_INCOME_ERROR,
      });
    }
  });
}

function getFinancialContactsFromDB(addressUid, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `financial/${uid}/${addressUid}/contacts`));
}

export function* getFinancialContactsRequest() {
  yield takeEvery([
    actions.CHECK_FINANCIAL_TRANSACTIONS_REQUEST,
    actions.GET_FINANCIAL_CONTACTS_REQUEST,
  ], function* () {
    try {
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const contacts = yield call(
        getFinancialContactsFromDB,
        addressUid,
        mainUser,
      );
      let formattedContacts = [];
      if (contacts.val()) {
        formattedContacts = _.map(contacts.val(), (val, id) => (
          { ...val, id }
        ));
      }
      yield put({
        type: actions.GET_FINANCIAL_CONTACTS_SUCCESS,
        payload: formattedContacts,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_FINANCIAL_CONTACTS_ERROR,
      });
    }
  });
}

function createManualExpenseOnDB(
  values,
  // costCenterProfile,
  addressUid,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const pushKey = push(child(ref(db), `/financial/${uid}/${addressUid}/manualOps`)).key;
  updates[`/financial/${uid}/${addressUid}/manualOps/${pushKey}`] = {
    ...values,
    invoiceStatus: 'confirmed',
    // costCenterProfile,
    type: 'expense',
  };
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* createManualExpenseRequest() {
  yield takeLatest(actions.CREATE_MANUAL_EXPENSE_REQUEST, function* (action) {
    const currentAddress = yield select(getSelectedAddressFromStore);
    try {
      yield put({ type: actions.CREATING_MANUAL_OPS });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      // const selectedCostCenterProfile = yield select(getSelectedCostCenterProfileFromStore);
      const payloadObject = {
        ...action.payload,
      };
      if (action.payload.selectedFinancialContact) {
        let contactsList = yield select(getFinancialContactsFromStore);
        let foundContact = contactsList.find((el) => (
          el.id === action.payload.selectedFinancialContact || el.name === action.payload.selectedFinancialContact
        ));
        if (!foundContact) {
          // It is a new payer.
          yield call(
            createFinancialContactOnDB,
            action.payload.selectedFinancialContact,
            currentAddress,
            mainUser,
          );
          yield put({
            type: actions.GET_FINANCIAL_CONTACTS_REQUEST,
          });
          yield take(actions.GET_FINANCIAL_CONTACTS_SUCCESS);
          contactsList = yield select(getFinancialContactsFromStore);
          foundContact = contactsList.find((el) => (
            el.id === action.payload.selectedFinancialContact || el.name === action.payload.selectedFinancialContact
          ));
        }
        payloadObject.selectedFinancialContact = foundContact.id;
      }
      yield call(
        createManualExpenseOnDB,
        payloadObject,
        // selectedCostCenterProfile[currentAddress],
        currentAddress,
        mainUser,
      );
      yield put({
        type: actions.CREATE_MANUAL_EXPENSE_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_MANUAL_EXPENSE_ERROR,
      });
    }
  });
}

function createFinalizedAppointmentsListener(addressId, mainUser, time, timeRange = []) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (timeRange?.length > 0) {
    const [startToUse, endToUse] = timeRange;
    const listener = eventChannel((emit) => {
      const databaseRef = ref(db, `finalizedAppointments/${uid}/${addressId}`);
      const queryRef = dbQuery(
        databaseRef,
        orderByChild('timestamp'),
        startAt(startToUse),
        endAt(`${endToUse}\uf8ff`),
      );
      const unsubscribe = onValue(queryRef, (req) => (
        emit(req.val() || {})
      ));
      return () => unsubscribe();
    });
    return listener;
  }
  const startToUse = moment()
    .tz('America/Sao_Paulo')
    .subtract(time || 1, 'months')
    .format('YYYY-MM-DD');
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `finalizedAppointments/${uid}/${addressId}`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('timestamp'),
      startAt(`${startToUse}\uf8ff`),
    );
    const unsubscribe = onValue(queryRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

function fetchAppointmentFromDB(id, agendaId, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `requests/${uid}/${agendaId}/confirmed/${id}`));
}

function* fetchAppointmentsArray(arrToFetch, mainUser) {
  return yield all(arrToFetch.map((el) => call(fetchAppointmentFromDB, el.id, el.agendaId, mainUser)));
}

function fetchPatientDataFromDB(patientId, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(child(dbRef, `/unified/${unified.id}/patients/${patientId}`));
  }
  return get(child(dbRef, `/users/${uid}/patients/${patientId}`));
}

function getTransactionFromDB(transactionId, costCenterProfile, addressUid, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `financial/${uid}/${addressUid}/transactions/${costCenterProfile}/${transactionId}`));
}

function* fetchTransactionsArray(arr, addressId, mainUser) {
  return yield all(arr.map((s) => call(getTransactionFromDB, s.key, s.costCenterKey, addressId, mainUser)));
}

export function* getFinalizedAppointmentsRequest() {
  yield takeLatest([
    actions.GET_FINALIZED_APPOINTMENTS_REQUEST,
    // actions.UPDATE_FINALIZED_APPOINTMENT_SUCCESS,
  ], function* () {
    try {
      yield put({ type: actions.FETCHING_FINALIZED_APPOINTMENTS_LIST });
      const addressUid = yield select(getSelectedAddressFromStore);
      // const agendaId = yield select(getSelectedAgendaFromStore);
      const agendasArr = yield select(getAgendasArrFromStore);
      const defaultAgenda = agendasArr.find((el) => el.default);
      const mainUser = yield select(getMainUserFromStore);
      const listenerTime = yield select(getFinalizedAppointmentListenerTimeFromStore);
      const listenerCustomTime = yield select(getFinalizedAppointmentListenerCustomTimeFromStore);
      const listenerTimeRange = yield select(getFinalizedAppointmentListenerTimeRangeFromStore);
      const finalizedAppointmentsListener = yield call(
        createFinalizedAppointmentsListener,
        addressUid,
        mainUser,
        listenerCustomTime || listenerTime,
        listenerTimeRange,
      );
      yield takeEvery(finalizedAppointmentsListener, function* (finalizedAppointments) {
        const finalizedAppointmentsArr = _.map(finalizedAppointments, (val, appointmentId) => (
          {
            ...val,
            id: appointmentId,
            operationType: 'income',
            appointment: true,
          }
        ));
        const appointmentsInfoArr = [];
        const transactionsArr = [];
        finalizedAppointmentsArr.forEach((el) => {
          // appointmentsInfoArr.push(el.id);
          appointmentsInfoArr.push({
            id: el.id,
            agendaId: el.agenda || defaultAgenda.id,
          });
          if (el.transactions) {
            Object.keys(el.transactions).forEach((costCenterKey) => {
              Object.keys(el.transactions[costCenterKey]).forEach((transactionKey) => {
                transactionsArr.push({
                  key: transactionKey,
                  costCenterKey,
                });
              });
            });
          }
        });
        const appointmentArrayResponse = yield call(
          fetchAppointmentsArray,
          appointmentsInfoArr,
          // agendaId,
          mainUser,
        );
        const userInfoArr = [];
        if (appointmentArrayResponse.length > 0) {
          appointmentArrayResponse.forEach((el, index) => {
            if (el.val()) {
              finalizedAppointmentsArr[index].appointmentInfo = { ...el.val() };
            } else if (finalizedAppointmentsArr[index].patient) {
              userInfoArr[index] = finalizedAppointmentsArr[index].patient;
            }
          });
        }
        if (userInfoArr.length > 0) {
          const unified = yield select(getUnifiedTokenStore);
          for (let i = 0; i < userInfoArr.length; i += 1) {
            if (userInfoArr[i]) {
              const userInfo = yield call(
                fetchPatientDataFromDB,
                userInfoArr[i],
                addressUid,
                unified,
                mainUser,
              );
              if (userInfo.val()) {
                finalizedAppointmentsArr[i].appointmentInfo = {
                  userInfo: { ...userInfo.val() },
                };
              }
            }
          }
        }
        const transactionsResponses = yield call(fetchTransactionsArray, transactionsArr, addressUid, mainUser);
        transactionsResponses.forEach((response) => {
          if (response.val()) {
            const transaction = response.val();
            const index = finalizedAppointmentsArr.findIndex((el) => el.id === transaction.key);
            if (index > -1) {
              if (!finalizedAppointmentsArr[index].transactionsArr) {
                finalizedAppointmentsArr[index].transactionsArr = [];
              }
              finalizedAppointmentsArr[index].transactionsArr.push({
                ...transaction,
                id: response.key,
              });
            }
          }
        });
        yield put({
          type: actions.GET_FINALIZED_APPOINTMENTS_SUCCESS,
          payload: {
            finalizedAppointmentsArr,
            // finalizedAppointmentsObj,
            address: addressUid,
          },
        });
      });
      const actionTriggered = yield take([
        appActions.CANCEL_LISTENERS,
        actions.CHANGE_FINALIZED_APPOINTMENT_LISTENER_TIMER,
      ]);
      finalizedAppointmentsListener.close();
      if (actionTriggered?.type === 'CHANGE_FINALIZED_APPOINTMENT_LISTENER_TIMER') {
        // Resetting listener to filter new 'costProfile'.
        yield put({
          type: actions.GET_FINALIZED_APPOINTMENTS_REQUEST,
        });
      } else {
        // It is the general "cancel all listeners".
        yield all([
          put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
          put({ type: actions.RESET_FINALIZED_APPOINTMENTS }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_FINALIZED_APPOINTMENTS_ERROR,
      });
    }
  });
}

function updateFinalizedAppointmentOnDB(
  values,
  appointment,
  addressId,
  agendaId,
  idToken,
  unified,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  Object.keys(values).forEach((field) => {
    if (field !== 'id') {
      updates[`/finalizedAppointments/${uid}/${addressId}/${values.id}/${field}`] = values[field];
    }
    if (field === 'proceduresArr' && appointment.proceduresArr) {
      const updatedAppointmentProceduresArr = _.cloneDeep(appointment.proceduresArr);
      values[field].forEach((el) => {
        const foundProcIndex = appointment.proceduresArr.findIndex((p) => p.id === el.procedureId);
        if (foundProcIndex > -1 && el.mode) {
          updatedAppointmentProceduresArr[foundProcIndex].mode = { ...el.mode };
        }
      });
      updates[`/requests/${uid}/${agendaId}/confirmed/${values.id}/proceduresArr`] = updatedAppointmentProceduresArr;
    }
  });
  // if (confirmInvoice) {
  //   updates[`/finalizedAppointments/${uid}/${addressId}/${values.id}/invoiceStatus`] = 'confirmed';
  // }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updateFinalizedAppointmentRequest() {
  yield takeEvery(actions.UPDATE_FINALIZED_APPOINTMENT_REQUEST, function* (action) {
    try {
      const { values, appointmentInfo } = action.payload;
      yield put({
        type: actions.UPDATING_FINALIZED_APPOINTMENT,
        // payload: invoice ? 'submit' : 'later',
      });
      yield call(sleep, 500);
      const idToken = yield call(getIdToken);
      const unified = yield select(getUnifiedTokenStore);
      const addressId = yield select(getSelectedAddressFromStore);
      // const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        updateFinalizedAppointmentOnDB,
        values,
        appointmentInfo,
        addressId,
        values.agenda || addressId, // agendaId to update appointment
        idToken,
        unified,
        mainUser,
      );
      yield all([
        put({ type: actions.UPDATE_FINALIZED_APPOINTMENT_SUCCESS }),
        put({ type: actions.CHECK_FINANCIAL_TRANSACTIONS_REQUEST }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.UPDATE_FINALIZED_APPOINTMENT_ERROR });
    }
  });
}

function removeFinalizedAppointmentOnDB(
  item,
  addressUid,
  agendaId,
  idToken,
  unified,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/finalizedAppointments/${uid}/${addressUid}/${item.id}`] = null;
  if (item.transactionsArr) {
    item.transactionsArr.forEach((el) => {
      if (el.paymentDate && el.paymentValue) {
        updates[`financial/${uid}/${addressUid}/cashBalanceHistory/${el.costCenterProfile}/${el.paymentDate}`] = increment(parseFloat(-el.paymentValue));
      }
    });
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeFinalizedAppointmentRequest() {
  yield takeEvery(actions.REMOVE_FINALIZED_APPOINTMENT_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.REMOVING_FINALIZED_APPOINTMENT,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const idToken = yield call(getIdToken);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        removeFinalizedAppointmentOnDB,
        action.payload,
        addressUid,
        agendaId,
        idToken,
        unified,
        mainUser,
      );
      yield all([
        put({ type: actions.REMOVE_FINALIZED_APPOINTMENT_SUCCESS }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.REMOVE_FINALIZED_APPOINTMENT_ERROR });
    }
  });
}

function createManualOpsListener(addressUid, mainUser, time, timeRange = []) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (timeRange?.length > 0) {
    const [startToUse, endToUse] = timeRange;
    const listener = eventChannel((emit) => {
      const databaseRef = ref(db, `financial/${uid}/${addressUid}/manualOps`);
      const queryRef = dbQuery(
        databaseRef,
        orderByChild('timestamp'),
        startAt(startToUse),
        endAt(`${endToUse}\uf8ff`),
      );
      const unsubscribe = onValue(queryRef, (req) => (
        emit(req.val() || {})
      ));
      return () => unsubscribe();
    });
    return listener;
  }
  const startToUse = moment()
    .tz('America/Sao_Paulo')
    .subtract(time || 1, 'months')
    .format('YYYY-MM-DD');
  const listener = eventChannel((emit) => {
    const databaseRef = ref(db, `financial/${uid}/${addressUid}/manualOps`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('timestamp'),
      startAt(startToUse),
    );
    const unsubscribe = onValue(queryRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

export function* getManualOpsRequest() {
  yield takeLatest([
    actions.GET_MANUAL_OPS_REQUEST,
    // actions.UPDATE_MANUAL_OPS_SUCCESS,
  ], function* () {
    try {
      yield put({ type: actions.FETCHING_MANUAL_OPS_LIST });
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const listenerTime = yield select(getManualOpsListenerTimeFromStore);
      const listenerCustomTime = yield select(getManualOpsListenerCustomTimeFromStore);
      const listenerTimeRange = yield select(getManualOpsListenerTimeRangeFromStore);
      const manualOpsListener = yield call(
        createManualOpsListener,
        addressUid,
        mainUser,
        listenerCustomTime || listenerTime,
        listenerTimeRange,
      );
      yield takeEvery(manualOpsListener, function* (manualOps) {
        const manualOpsArr = _.map(manualOps, (val, opsId) => (
          {
            ...val,
            id: opsId,
          }
        ));
        const transactionsArr = [];
        manualOpsArr.forEach((el) => {
          if (el.transactions) {
            Object.keys(el.transactions).forEach((costCenterKey) => {
              Object.keys(el.transactions[costCenterKey]).forEach((transactionKey) => {
                transactionsArr.push({
                  key: transactionKey,
                  costCenterKey,
                });
              });
            });
          }
        });
        const transactionsResponses = yield call(fetchTransactionsArray, transactionsArr, addressUid, mainUser);
        transactionsResponses.forEach((response) => {
          if (response.val()) {
            const transaction = response.val();
            const index = manualOpsArr.findIndex((el) => el.id === transaction.key);
            if (index > -1) {
              if (!manualOpsArr[index].transactionsArr) {
                manualOpsArr[index].transactionsArr = [];
              }
              manualOpsArr[index].transactionsArr.push({
                ...transaction,
                id: response.key,
              });
            }
          }
        });
        yield put({
          type: actions.GET_MANUAL_OPS_SUCCESS,
          payload: {
            // manualOpsList: manualOpsArr,
            manualOpsArr,
            // manualOpsObj,
            address: addressUid,
          },
        });
      });
      const actionTriggered = yield take([
        appActions.CANCEL_LISTENERS,
        actions.CHANGE_MANUAL_OPS_LISTENER_TIMER,
      ]);
      manualOpsListener.close();
      if (actionTriggered?.type === 'CHANGE_MANUAL_OPS_LISTENER_TIMER') {
        // Resetting listener to filter new 'costProfile'.
        yield put({
          type: actions.GET_MANUAL_OPS_REQUEST,
        });
      } else {
        // It is the general "cancel all listeners".
        yield all([
          put({ type: appActions.CANCEL_LISTENERS_SUCCESS }),
          put({ type: actions.RESET_MANUAL_OPS }),
        ]);
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_MANUAL_OPS_ERROR,
      });
    }
  });
}

function updateManualOpsOnDB(
  values,
  addressUid,
  idToken,
  unified,
  mainUser,
) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const obj = {
    ...values,
    id: null,
    transactionsArr: null,
  };
  return update(ref(db, `/financial/${uid}/${addressUid}/manualOps/${values.id}`), obj);
  // return new Promise((resolve, reject) => {
  //   db.ref(`/financial/${uid}/${addressUid}/manualOps/${values.id}`).update(obj, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* updateManualOpsRequest() {
  yield takeEvery(actions.UPDATE_MANUAL_OPS_REQUEST, function* (action) {
    try {
      const { values } = action.payload;
      yield put({
        type: actions.UPDATING_MANUAL_OPS,
        // payload: invoice ? 'submit' : 'later',
      });
      yield call(sleep, 500);
      const idToken = yield call(getIdToken);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      if (values.selectedFinancialContact) {
        let contactsList = yield select(getFinancialContactsFromStore);
        let foundContact = contactsList.find((el) => (
          el.id === values.selectedFinancialContact || el.name === values.selectedFinancialContact
        ));
        if (!foundContact) {
          // It is a new payer.
          yield call(
            createFinancialContactOnDB,
            values.selectedFinancialContact,
            addressUid,
            mainUser,
          );
          yield put({
            type: actions.GET_FINANCIAL_CONTACTS_REQUEST,
          });
          yield take(actions.GET_FINANCIAL_CONTACTS_SUCCESS);
          contactsList = yield select(getFinancialContactsFromStore);
          foundContact = contactsList.find((el) => (
            el.id === values.selectedFinancialContact || el.name === values.selectedFinancialContact
          ));
        }
        values.selectedFinancialContact = foundContact.id;
      }
      yield call(
        updateManualOpsOnDB,
        values,
        addressUid,
        idToken,
        unified,
        mainUser,
      );
      yield put({
        type: actions.UPDATE_MANUAL_OPS_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.UPDATE_MANUAL_OPS_ERROR });
    }
  });
}

function removeManualOpsOnDB(
  item,
  addressUid,
  idToken,
  unified,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`/financial/${uid}/${addressUid}/manualOps/${item.id}`] = null;
  if (item.transactionsArr) {
    item.transactionsArr.forEach((el) => {
      if (el.paymentDate && el.paymentValue) {
        updates[`financial/${uid}/${addressUid}/cashBalanceHistory/${el.costCenterProfile}/${el.paymentDate}`] = increment(parseFloat(-el.paymentValue));
      }
    });
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeManualOpsRequest() {
  yield takeEvery(actions.REMOVE_MANUAL_OPS_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.REMOVING_MANUAL_OPS,
        payload: action.payload.id,
      });
      yield call(sleep, 500);
      const idToken = yield call(getIdToken);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        removeManualOpsOnDB,
        action.payload,
        addressUid,
        idToken,
        unified,
        mainUser,
      );
      yield all([
        put({ type: actions.REMOVE_MANUAL_OPS_SUCCESS }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.REMOVE_MANUAL_OPS_ERROR });
    }
  });
}

function getBalanceHistoryFromDB(currentAddress, mainUser, costCenterProfile) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `financial/${uid}/${currentAddress}/accrualBalanceHistory/${costCenterProfile}`));
}

function getCashBalanceHistoryFromDB(currentAddress, mainUser, costCenterProfile) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `financial/${uid}/${currentAddress}/cashBalanceHistory/${costCenterProfile}`));
}

export function* getBalanceHistoryRequest() {
  yield takeLatest([
    actions.GET_BALANCE_HISTORY_REQUEST,
    actions.GET_BALANCE_SUCCESS,
    actions.CHECK_FINANCIAL_TRANSACTIONS_REQUEST,
  ], function* () {
    try {
      yield put({ type: actions.FETCHING_BALANCE_HISTORY });
      yield call(sleep, 500);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);

      let selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      while (!selectedCostCenterProfileArr[addressUid]) {
        yield take(actions.SELECT_COST_CENTER_PROFILE_ARR);
        selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      }
      const [accrualBalanceHistoryArr, cashBalanceHistoryArr] = yield all([
        all(selectedCostCenterProfileArr[addressUid].map((costCenter) => call(
          getBalanceHistoryFromDB,
          addressUid,
          mainUser,
          costCenter,
        ))),
        all(selectedCostCenterProfileArr[addressUid].map((costCenter) => call(
          getCashBalanceHistoryFromDB,
          addressUid,
          mainUser,
          costCenter,
        ))),
      ]);
      yield put({
        type: actions.RESET_BALANCE_HISTORY,
      });
      for (let i = 0; i < selectedCostCenterProfileArr[addressUid].length; i += 1) {
        yield put({
          type: actions.GET_BALANCE_HISTORY_SUCCESS,
          payload: {
            accrualBalanceHistory: accrualBalanceHistoryArr[i].val() || {},
            cashBalanceHistory: cashBalanceHistoryArr[i].val() || {},
            costCenterProfile: selectedCostCenterProfileArr[addressUid][i],
            address: addressUid,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_BALANCE_HISTORY_ERROR,
      });
    }
  });
}

function getNextTransactionsFromCloud(
  costCenterProfile,
  addressId,
  idToken,
  mainUser,
) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressId,
    costCenterProfile,
    nextTransactions: true,
    // pastCashTransactions: true,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/getBankStatement`,
    bodyParameters,
    config,
  );
}

export function* getNextTransactionsRequest() {
  yield takeLatest([
    actions.GET_NEXT_TRANSACTIONS_REQUEST,
    actions.CHECK_FINANCIAL_TRANSACTIONS_REQUEST,
  ], function* () {
    try {
      yield put({ type: actions.FETCHING_NEXT_TRANSACTIONS });
      const addressUid = yield select(getSelectedAddressFromStore);
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);

      let selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      while (!selectedCostCenterProfileArr[addressUid]) {
        yield take(actions.SELECT_COST_CENTER_PROFILE_ARR);
        selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      }
      const nextTransactionsArr = yield all(selectedCostCenterProfileArr[addressUid].map((costCenter) => call(
        getNextTransactionsFromCloud,
        costCenter,
        addressUid,
        idToken,
        mainUser,
      )));
      for (let i = 0; i < selectedCostCenterProfileArr[addressUid].length; i += 1) {
        const { data } = nextTransactionsArr[i];
        if (data) {
          yield put({
            type: actions.GET_NEXT_TRANSACTIONS_SUCCESS,
            payload: {
              nextTransactionsList: _.orderBy(data, (e) => (e?.transactionInfo?.date ? e.transactionInfo.date : ''), ['asc']),
              costCenterProfile: selectedCostCenterProfileArr[addressUid][i],
            },
          });
        }
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_NEXT_TRANSACTIONS_ERROR,
      });
    }
  });
}

function getMonthIncomeFromDB(currentAddress, mainUser, costCenterProfile) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const currentDate = moment().format('YYYY-MM-DD');
  const db = getDatabase();
  const databaseRef = ref(db, `financial/${uid}/${currentAddress}/transactions/${costCenterProfile}`);
  return get(dbQuery(
    databaseRef,
    orderByChild('date'),
    startAt(startOfMonth),
    endAt(`${currentDate}\uf8ff`),
  ));
}

function getCashMonthIncomeFromDB(currentAddress, mainUser, costCenterProfile) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const currentDate = moment().format('YYYY-MM-DD');
  const db = getDatabase();
  const databaseRef = ref(db, `financial/${uid}/${currentAddress}/transactions/${costCenterProfile}`);
  return get(dbQuery(
    databaseRef,
    orderByChild('paymentDate'),
    startAt(startOfMonth),
    endAt(`${currentDate}\uf8ff`),
  ));
}

export function* getMonthIncomeRequest() {
  yield takeLatest([
    // actions.GET_MONTH_INCOME_REQUEST,
    actions.SELECT_COST_CENTER_PROFILE_ARR,
    actions.CHECK_FINANCIAL_TRANSACTIONS_REQUEST,
  ], function* () {
    try {
      yield put({ type: actions.FETCHING_MONTH_INCOME_VALUES });
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);

      let selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      while (!selectedCostCenterProfileArr[addressUid]) {
        yield take(actions.SELECT_COST_CENTER_PROFILE_ARR);
        selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      }
      const [monthIncomeArr, cashMonthIncomeArr] = yield all([
        all(selectedCostCenterProfileArr[addressUid].map((costCenter) => call(
          getMonthIncomeFromDB,
          addressUid,
          mainUser,
          costCenter,
        ))),
        all(selectedCostCenterProfileArr[addressUid].map((costCenter) => call(
          getCashMonthIncomeFromDB,
          addressUid,
          mainUser,
          costCenter,
        ))),
      ]);

      for (let i = 0; i < selectedCostCenterProfileArr[addressUid].length; i += 1) {
        let monthIncomeValue = 0;
        let monthExpenseValue = 0;
        if (monthIncomeArr[i].exists()) {
          monthIncomeArr[i].forEach((el) => {
            if (el.val().value < 0) {
              monthExpenseValue += el.val().value;
            } else {
              monthIncomeValue += el.val().value;
            }
          });
        }
        let cashMonthIncomeValue = 0;
        let cashMonthExpenseValue = 0;
        if (cashMonthIncomeArr[i].exists()) {
          cashMonthIncomeArr[i].forEach((el) => {
            if (_.isFinite(el.val().paymentValue)) {
              if (el.val().paymentValue < 0) {
                cashMonthExpenseValue += el.val().paymentValue;
              } else {
                cashMonthIncomeValue += el.val().paymentValue;
              }
            }
          });
        }
        yield put({
          type: actions.GET_MONTH_INCOME_SUCCESS,
          payload: {
            monthIncomeValue,
            monthExpenseValue,
            cashMonthIncomeValue,
            cashMonthExpenseValue,
            // address: addressUid,
            costCenterProfile: selectedCostCenterProfileArr[addressUid][i],
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_MONTH_INCOME_ERROR,
      });
    }
  });
}

function updateCashPaymentOnDB(
  date,
  value,
  transaction,
  addressUid,
  idToken,
  unified,
  mainUser,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const {
    costCenterProfile,
    id,
  } = transaction;
  const updates = {};
  updates[`financial/${uid}/${addressUid}/transactions/${costCenterProfile}/${id}/paymentValue`] = value;
  if (value) {
    updates[`financial/${uid}/${addressUid}/transactions/${costCenterProfile}/${id}/paymentDate`] = date;
    updates[`financial/${uid}/${addressUid}/transactions/${costCenterProfile}/${id}/payment`] = 'fulfilled';
  } else {
    updates[`financial/${uid}/${addressUid}/transactions/${costCenterProfile}/${id}/paymentDate`] = null;
    updates[`financial/${uid}/${addressUid}/transactions/${costCenterProfile}/${id}/payment`] = null;
  }
  let differentDate = false;
  if (transaction?.paymentDate) {
    if (date !== transaction.paymentDate) {
      differentDate = true;
      // Need to remove the old value from the old date.
      updates[`financial/${uid}/${addressUid}/cashBalanceHistory/${costCenterProfile}/${transaction.paymentDate}`] = increment(parseFloat(-transaction.paymentValue));
      // Adding the new value to the new date.
      updates[`financial/${uid}/${addressUid}/cashBalanceHistory/${costCenterProfile}/${date}`] = increment(value);
    }
  }
  if (!differentDate) {
    const afterValue = value;
    let beforeValue = 0;
    if (transaction?.paymentValue) {
      beforeValue = transaction.paymentValue;
    }
    const diffValue = afterValue - beforeValue;
    if (diffValue) {
      updates[`financial/${uid}/${addressUid}/cashBalanceHistory/${costCenterProfile}/${date}`] = increment(diffValue);
    }
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function getSingleTransaction(
  id,
  costCenterProfile,
  addressUid,
  mainUser,
) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `financial/${uid}/${addressUid}/transactions/${costCenterProfile}/${id}`));
}

export function* confirmCashPaymentRequest() {
  yield takeEvery(actions.CONFIRM_CASH_PAYMENT_REQUEST, function* (action) {
    try {
      const {
        date,
        value,
        transaction,
        bankStatement,
        patientFinancialHistoryId,
      } = action.payload;
      yield put({
        type: actions.LOADING_CASH_PAYMENT,
        payload: transaction.id,
      });
      yield call(sleep, 500);
      const idToken = yield call(getIdToken);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(
        updateCashPaymentOnDB,
        date,
        value,
        transaction,
        addressUid,
        idToken,
        unified,
        mainUser,
      );
      if (bankStatement) {
        yield put({
          type: actions.UPDATE_BANK_STATEMENT_LIST_REQUEST,
          payload: transaction,
        });
      }
      if (patientFinancialHistoryId) {
        const updatedTransaction = yield call(
          getSingleTransaction,
          transaction.id,
          transaction.costCenterProfile,
          addressUid,
          mainUser,
        );
        const financialHistory = yield select(getFinancialHistoryFromStore);
        const seectedId = yield select(getSeectedIdFromStore);
        if (financialHistory[seectedId]) {
          const foundHistoryIndex = financialHistory[seectedId].findIndex((el) => el.appointmentKey === patientFinancialHistoryId);
          if (foundHistoryIndex > -1) {
            const foundTransacIndex = financialHistory[seectedId][foundHistoryIndex].transactionsArr.findIndex((el) => el.id === transaction.id);
            if (foundTransacIndex > -1) {
              financialHistory[seectedId][foundHistoryIndex].transactionsArr[foundTransacIndex] = {
                ...updatedTransaction.val(),
                id: transaction.id,
              };
              yield all([
                put({
                  type: contactActions.GET_PATIENT_FINANCIAL_HISTORY_SUCCESS,
                  payload: {
                    list: financialHistory[seectedId],
                    seectedId,
                  },
                }),
                put({
                  type: actions.CONFIRM_CASH_PAYMENT_SUCCESS,
                }),
              ]);
            }
          }
        }
      } else {
        yield put({
          type: actions.RELOAD_TRANSACTIONS_LIST_REQUEST,
          payload: transaction,
        });
      }
      yield put({
        type: actions.CHECK_CASH_BALANCE_HISTORY,
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.CONFIRM_CASH_PAYMENT_ERROR });
    }
  });
}

export function* updateBankStatementListRequest() {
  yield takeEvery(actions.UPDATE_BANK_STATEMENT_LIST_REQUEST, function* (action) {
    try {
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);

      let selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      while (!selectedCostCenterProfileArr[addressUid]) {
        yield take(actions.SELECT_COST_CENTER_PROFILE_ARR);
        selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      }

      const costCenter = action.payload.costCenterProfile;
      if (selectedCostCenterProfileArr[addressUid].some((costCenterKey) => costCenterKey === costCenter)) {
        const transaction = yield call(
          getSingleTransaction,
          action.payload.id,
          action.payload.costCenterProfile,
          addressUid,
          mainUser,
        );
        if (transaction.val()) {
          const transactionObj = {
            ...transaction.val(),
            id: transaction.key,
          };
          const bankStatementList = yield select(getBankStatementListFromStore);
          if (bankStatementList) {
            const index = bankStatementList.findIndex((el) => el.transactionKey === transactionObj.id);
            if (index > -1 && bankStatementList[index].transactionInfo) {
              bankStatementList[index].transactionInfo = _.cloneDeep(transactionObj);
              yield put({
                type: actions.GET_BANK_STATEMENT_SUCCESS,
                payload: {
                  bankStatementList: _.orderBy(bankStatementList, (e) => (e?.transactionInfo?.date ? e.transactionInfo.date : ''), ['asc']),
                },
              });
            }
          }
          const nextTransactionsList = yield select(getNextTransactionsListFromStore);
          if (nextTransactionsList && nextTransactionsList[costCenter]) {
            const index = nextTransactionsList[costCenter].findIndex((el) => el.transactionKey === transactionObj.id);
            if (index > -1 && nextTransactionsList[costCenter][index].transactionInfo) {
              nextTransactionsList[costCenter][index].transactionInfo = _.cloneDeep(transactionObj);
              yield put({
                type: actions.GET_NEXT_TRANSACTIONS_SUCCESS,
                payload: {
                  nextTransactionsList: _.orderBy(
                    nextTransactionsList[costCenter],
                    (e) => (e?.transactionInfo?.date ? e.transactionInfo.date : ''),
                    ['asc'],
                  ),
                  costCenterProfile: costCenter,
                },
              });
            }
          }
        }
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.CONFIRM_CASH_PAYMENT_ERROR });
    }
  });
}

export function* reloadTransactionsListRequest() {
  yield takeEvery(actions.RELOAD_TRANSACTIONS_LIST_REQUEST, function* (action) {
    try {
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const transaction = yield call(
        getSingleTransaction,
        action.payload.id,
        action.payload.costCenterProfile,
        addressUid,
        mainUser,
      );
      if (transaction.val()) {
        const transactionObj = {
          ...transaction.val(),
          id: transaction.key,
        };
        if (action.payload.source.includes('appointment')) {
          // It is a 'finalizedAppointment' entrance.
          const finalizedAppointments = yield select(getFinalizedAppointmentsFromStore);
          if (finalizedAppointments) {
            // Locating appointment among 'finalizedAppointments'.
            const foundAppointmentIndex = finalizedAppointments.findIndex((el) => el.id === transactionObj.key);
            if (foundAppointmentIndex > -1 && finalizedAppointments[foundAppointmentIndex]?.transactionsArr) {
              // Locating index from 'transactionsArr'.
              const transactionIndex = finalizedAppointments[foundAppointmentIndex].transactionsArr
                .findIndex((el) => el.id === transactionObj.id);
              // Replacing the transaction with the updated one.
              finalizedAppointments[foundAppointmentIndex].transactionsArr[transactionIndex] = transactionObj;
            }
            yield all([
              put({
                type: actions.CONFIRM_CASH_PAYMENT_SUCCESS,
              }),
              put({
                type: actions.GET_FINALIZED_APPOINTMENTS_SUCCESS,
                payload: {
                  finalizedAppointmentsArr: finalizedAppointments,
                  address: addressUid,
                },
              }),
            ]);
          } else {
            yield put({
              type: actions.CONFIRM_CASH_PAYMENT_SUCCESS,
            });
          }
        }
        if (action.payload.source === 'manual-income' || action.payload.source === 'manual-expense') {
          // It is a 'manualOps' entrance.
          const manualOps = yield select(getManualOpsFromStore);
          if (manualOps) {
            // Locating operation among 'manualOps'.
            const manualOpsIndex = manualOps.findIndex((el) => el.id === transactionObj.key);
            if (manualOpsIndex > -1 && manualOps[manualOpsIndex]?.transactionsArr) {
              // Locating index from 'transactionsArr'.
              const transactionIndex = manualOps[manualOpsIndex].transactionsArr
                .findIndex((el) => el.id === transactionObj.id);
              // Replacing the transaction with the updated one.
              manualOps[manualOpsIndex].transactionsArr[transactionIndex] = transactionObj;
            }
            yield all([
              put({
                type: actions.CONFIRM_CASH_PAYMENT_SUCCESS,
              }),
              put({
                type: actions.GET_MANUAL_OPS_SUCCESS,
                payload: {
                  manualOpsArr: manualOps,
                  address: addressUid,
                },
              }),
            ]);
          } else {
            yield put({
              type: actions.CONFIRM_CASH_PAYMENT_SUCCESS,
            });
          }
        }
        if (action.payload.source === 'inventory') {
          // It is a 'inventory' entrance.
          const inventoryOps = yield select(getInventoryOpsFromStore);
          if (inventoryOps) {
            // Locating operation among 'inventoryOps'.
            const inventoryOpsIndex = inventoryOps.findIndex((el) => el.id === transactionObj.key);
            if (inventoryOpsIndex > -1 && inventoryOps[inventoryOpsIndex]?.transactionsArr) {
              // Locating index from 'transactionsArr'.
              const transactionIndex = inventoryOps[inventoryOpsIndex].transactionsArr
                .findIndex((el) => el.id === transactionObj.id);
              // Replacing the transaction with the updated one.
              inventoryOps[inventoryOpsIndex].transactionsArr[transactionIndex] = transactionObj;
            }
            yield all([
              put({
                type: actions.CONFIRM_CASH_PAYMENT_SUCCESS,
              }),
              put({
                type: inventoryActions.INVENTORY_OPS_FETCH_SUCCESS,
                payload: {
                  inventoryOpsArr: inventoryOps,
                  address: addressUid,
                },
              }),
            ]);
          } else {
            yield put({
              type: actions.CONFIRM_CASH_PAYMENT_SUCCESS,
            });
          }
        }
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.CONFIRM_CASH_PAYMENT_ERROR });
    }
  });
}

function removeCashBalanceHistoryDates(
  datesToRemove,
  addressUid,
  mainUser,
  constCenterProfile,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  datesToRemove.forEach((date) => {
    updates[`financial/${uid}/${addressUid}/cashBalanceHistory/${constCenterProfile}/${date}`] = null;
  });
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* checkCashBalanceHistory() {
  yield takeLatest(actions.CHECK_CASH_BALANCE_HISTORY, function* () {
    try {
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);

      let selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      while (!selectedCostCenterProfileArr[addressUid]) {
        yield take(actions.SELECT_COST_CENTER_PROFILE_ARR);
        selectedCostCenterProfileArr = yield select(getSelectedCostCenterProfileArrFromStore);
      }
      const cashBalanceHistoryArr = yield all(selectedCostCenterProfileArr[addressUid].map((costCenter) => call(
        getCashBalanceHistoryFromDB,
        addressUid,
        mainUser,
        costCenter,
      )));
      for (let i = 0; i < selectedCostCenterProfileArr[addressUid].length; i += 1) {
        let cashHistoryDatesArr = [];
        if (cashBalanceHistoryArr[i].val()) {
          cashHistoryDatesArr = Object.keys(cashBalanceHistoryArr[i].val());
        }
        if (cashHistoryDatesArr.length > 90) {
          const datesToRemove = cashHistoryDatesArr.sort().reverse().slice(90);
          yield call(
            removeCashBalanceHistoryDates,
            datesToRemove,
            addressUid,
            mainUser,
            selectedCostCenterProfileArr[addressUid][i],
          );
        }
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.CONFIRM_CASH_PAYMENT_ERROR });
    }
  });
}

function fetchCardTaxesFromDB(mainUser, addressUid) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `financial/${uid}/${addressUid}/cardTaxes`));
}

function saveCardTaxesOnDB(cardTaxes, addressUid, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (cardTaxes.id) {
    updates[`financial/${uid}/${addressUid}/cardTaxes/${cardTaxes.id}`] = { ...cardTaxes, lastSaved: moment().tz('America/Sao_Paulo').format() };
  } else {
    const pushKey = push(child(ref(db), `financial/${uid}/${addressUid}/cardTaxes`)).key;
    updates[`financial/${uid}/${addressUid}/cardTaxes/${pushKey}`] = { ...cardTaxes, lastSaved: moment().tz('America/Sao_Paulo').format() };
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* getCardTaxes() {
  yield takeEvery([
    actions.GET_CARD_TAXES_REQUEST,
    actions.GET_FINANCIAL_SETTINGS_REQUEST,
  ], function* () {
    try {
      yield put({ type: actions.FETCHING_CARD_TAXES });
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const settings = yield call(fetchCardTaxesFromDB, mainUser, addressUid);
      if (!settings.val()) {
        // No settings on DB.
        const defaultCardTaxes = {
          title: '',
          debit: {
            administrationTax: 0,
            time: 0,
          },
          credit: {
            full: {
              administrationTax: 0,
              time: 0,
            },
            split: {
              splittedMode: '',
              administrationTax: 0,
              time: 0,
              individualWithCompoundMethod: false,
              individualSplittedTaxes: [{
                value: 0,
                key: '0',
              }],
              intervalSplittedTaxes: [{
                splits: 2,
                value: 0,
                key: '0',
              }],
            },
          },
        };
        yield call(saveCardTaxesOnDB, defaultCardTaxes, addressUid, mainUser);
        yield put({ type: actions.GET_CARD_TAXES_REQUEST });
      } else {
        // There is settings already.
        const settingsArray = _.map(settings.val(), (val, id) => (
          { ...val, id }
        ));
        yield put({
          type: actions.GET_CARD_TAXES_SUCCESS,
          payload: {
            cardTaxesArr: _.orderBy(settingsArray, (e) => (e?.title?.toLowerCase() || e?.id), ['asc']),
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_CARD_TAXES_ERROR,
      });
      notification('error', 'Não foi possível carregar as configurações salvas');
    }
  });
}

export function* saveCardTaxes() {
  yield takeEvery(actions.SAVE_CARD_SETTINGS_REQUEST, function* (action) {
    try {
      yield put({ type: actions.SAVING_CARD_TAXES });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(saveCardTaxesOnDB, action.payload, addressUid, mainUser);
      yield put({ type: actions.GET_CARD_TAXES_REQUEST });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SAVE_CARD_SETTINGS_ERROR,
      });
      notification('error', 'Não foi possível salvar as configurações do cartão');
    }
  });
}

function removeCardTaxesOnDB(id, addressUid, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`financial/${uid}/${addressUid}/cardTaxes/${id}`] = null;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeCardTaxes() {
  yield takeEvery(actions.REMOVE_CARD_SETTINGS_REQUEST, function* (action) {
    try {
      yield put({ type: actions.REMOVING_CARD_SETTINGS });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      yield call(removeCardTaxesOnDB, action.payload, addressUid, mainUser);
      yield put({ type: actions.GET_CARD_TAXES_REQUEST });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REMOVE_CARD_SETTINGS_ERROR,
      });
      notification('error', 'Não foi possível excluir as configurações');
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getFinancialSettingsRequest),
    fork(changeInvoiceAutomationRequest),
    fork(changeShowFinalizedAppointmentModalRequest),
    fork(getBalanceRequest),
    fork(getCashBalanceRequest),
    fork(checkFinancialTransactionsRequest),
    // fork(getOperationsListRequest),
    fork(getBankStatementRequest),
    fork(getCostCenterProfilesRequest),
    fork(costCenterSelected),
    fork(createCostCenterProfileRequest),
    fork(updateCostCenterProfileRequest),
    fork(removeCostCenterProfileRequest),
    fork(createManualIncomeRequest),
    fork(getFinancialContactsRequest),
    fork(createManualExpenseRequest),
    fork(getFinalizedAppointmentsRequest),
    fork(updateFinalizedAppointmentRequest),
    fork(removeFinalizedAppointmentRequest),
    fork(getManualOpsRequest),
    fork(updateManualOpsRequest),
    fork(removeManualOpsRequest),
    fork(getBalanceHistoryRequest),
    fork(getNextTransactionsRequest),
    fork(getMonthIncomeRequest),
    // fork(getTransactionsListRequest),
    fork(confirmCashPaymentRequest),
    fork(reloadTransactionsListRequest),
    fork(updateBankStatementListRequest),
    fork(checkCashBalanceHistory),
    fork(getCardTaxes),
    fork(saveCardTaxes),
    fork(removeCardTaxes),
  ]);
}
